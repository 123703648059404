const initialState = {
  promotions: [],
};
export default function payReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case "PAY-AMOUNT":
      return {
        ...state,
        promotions: action.payload,
      };
    default:
      return state;
  }
}
