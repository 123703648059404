const initialState = {
  download: {},
};
export default function downloadReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case "DOWNLOAD":
      return {
        ...state,
        download: action.payload,
      };
    default:
      return state;
  }
}
