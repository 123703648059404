const initialState = {
  chats: [],
  name: "",
  socket: {},
};
export default function chatReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case "CHAT":
      return {
        ...state,
        chats: state.chats.concat(action.payload),
      };
    case "SET-NAME":
      return {
        ...state,
        name: action.payload,
      };
    case "SOCKET":
      return {
        ...state,
        socket: action.payload,
      };
    default:
      return state;
  }
}
