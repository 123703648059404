import { axiosInstance } from "./axios";
import CryptoEncrypt from "../helpers/encoder";
class AuthService {
    login(userData, token) {
        userData.token   = token;
        const encodeUser = CryptoEncrypt.AESEncrypt(JSON.stringify(userData));
        const server     = localStorage.getItem("server");

        axiosInstance.defaults.headers.post["ChooseServer"] = server;
        return axiosInstance.post("/auth/login", { data: encodeUser });
    }
}

export default new AuthService();
