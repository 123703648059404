export const formatDate = (global__date) => {
  const time = new Date(Date.parse(global__date));
  const timestamp = time.getTime();
  const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

  const date = new Date(parseInt(timestamp));
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  let currentDay = "";
  if (day < 10) {
    currentDay = "0" + day.toString();
  } else {
    currentDay = day;
  }
  const format = `${year}-${month}-${currentDay}`;

  return format;
};

export const formatDateTime = (global__date) => {
  const time = new Date(Date.parse(global__date));
  const timestamp = time.getTime();
  const months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];

  const date = new Date(parseInt(timestamp));
  const day = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();

  const hour = date.getHours();
  const minutes = date.getMinutes();

  let currentDay = "";
  if (day < 10) {
    currentDay = "0" + day.toString();
  } else {
    currentDay = day;
  }
  const format = `${year}-${month}-${currentDay} ${hour}:${minutes}`;

  return format;
};

export const checkValidateEmail = (email) => {
  const pattern = new RegExp(
    /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
  );

  if (!pattern.test(email)) {
    return false;
  }
  return true;
};

export const checkValidPassword = (password) => {
  const pattern = new RegExp(/^(?=.*[a-zA-Z])(?=.*\d).{8,16}$/);
  if (!pattern.test(password)) {
    return false;
  }
  return true;
};

export const checkValidUsername = (username) => {
  const pattern = new RegExp(/^[a-z0-9]{6,}$/);
  if (!pattern.test(username)) {
    return false;
  }
  return true;
};

export const secureText = (string) => {
  const split = string.split("@");
  return "******@" + split[1];
};
