import React from "react";
import _ from "lodash";
import { useSelector } from "react-redux";
import PostsScrollToTop from "../Scroll/PostsScrollToTop";
import { formatDate } from "../../helpers/helpers";
const Post = () => {
  const post = useSelector((state) => state.newsReducer.detail);

  if (_.isEmpty(post)) {
    return <></>;
  }
  return (
    <>
      <PostsScrollToTop />
      <h2 className="text-center text-post-text-color text-base lg:text-5xl md:text-3xl akaya font-bold">
        {post.post_title}
      </h2>
      <div className="bg-post-com bg-no-repeat bg-post-time w-full h-11 mt-8 lg:ml-16"></div>
      <p
        className="text-sm md:text-base lg:text-lg text-post-text-color font-normal lg:ml-24 mt-4"
        style={{}}
      >
        From: {post?.from_date && formatDate(post?.from_date)} To:{" "}
        {post?.end_date && formatDate(post?.end_date)}
      </p>
      <div className="bg-post-com bg-no-repeat bg-post-detail w-full h-11 mt-8  lg:ml-12"></div>
      <div className="mx-auto prose prose-lg prose-indigo max-w-7xl">
        <article
          dangerouslySetInnerHTML={{ __html: post.post_content }}
          className="pb-80"
        ></article>
      </div>
    </>
  );
};

export default Post;
