import React from "react";
import rolling from "../../images/rolling.png";
import download_auto from "../../images/download-auto.gif";

//styled components
import styled from "styled-components";

import { Link } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../redux/action";
import { useZustandStore } from "../../zstore/index";

const Right = () => {
  const dispatch = useDispatch();

  const isLogin = useSelector((state) => state.userReducer.isLogin);

  const { download } = useZustandStore();

  // onLogout
  const onLogout = () => {
    dispatch(userLogout(false));
    dispatch({ type: "GET-POINT", payload: "" });
    sessionStorage.removeItem("_token");
  };

  return (
    <SiderRightScrolling>
      <img src={rolling} alt="rolling" className="cover" />
      <a href={download?.auto} target="_blank" rel="noopener noreferrer">
        <img src={download_auto} alt="download_auto" className="cover qrcode cursor-pointer" />
      </a>
      <SiderButtons>
        <Link to={isLogin ? "/" : "/login"} onClick={onLogout}>
          {isLogin ? "Logout" : "Login"}
        </Link>
        <Link to={isLogin ? "/profile" : "/register"}>{isLogin ? "Profile" : "Register"}</Link>
        <Link to="/ranking">Ranking</Link>
        <Link to="/recharge">Recharge</Link>
      </SiderButtons>
    </SiderRightScrolling>
  );
};
const SiderRightScrolling = styled.div`
  position: fixed;
  right: 0%;
  top: 35vh;
  z-index: 9999;
  opacity: 1;
  transition: 0.35s ease-in-out;
  width: 10%;
  height: auto;
  margin: 0 auto;

  .qrcode {
    position: absolute;
    top: 14%;
    left: 20.5%;
    width: 60%;
    height: auto;
    border: 2px solid rgba(255, 255, 255, 0.5);
    padding: 2%;
    border-radius: 8px;
  }
`;
const SiderButtons = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 6%;
  right: 17.3%;
  width: 65%;

  a {
    padding: 5% 2%;
    margin: 3% 0;
    border-radius: 8px;
    font-size: 0.6vw;
    background-color: rgba(255, 255, 255, 0.1);
    color: #ffffff;
    text-decoration: none;
    text-align: center;
  }
  a:hover {
    opacity: 0.5;
  }
`;
export default Right;
