import React from "react";
import bg1 from "../../images/bg1.jpg";
const Terms = () => {
  return (
    <div className="min-h-screen max-w-8xl mx-auto">
      <div className="w-full h-full relative">
        <img src={bg1} className="absolute w-full h-full" alt="bg" style={{ zIndex: -10 }} />
        <h2 className="text-black text-base md:text-xl lg:text-3xl text-center mt-16 font-medium">TERMS OF USE</h2>
        <div className="prose prose-sm lg:prose-lg mt-16 mx-auto prose-indigo  text-black max-w-7xl px-4">
          <p>
            These Terms of Service and our Privacy Policy at{" "}
            <a href="https://do-heart.com/privacy-statement" target="_">
              www.do-heart.com/privacy-statement
            </a>{" "}
            cover your access to the following services of Dragon Heart
          </p>
          <ol>
            <li>
              The DragonHeart game (<strong>the "Game"</strong>);
            </li>
            <li>
              The locally-installed game software that we make available for you to download for purposes of updating and launching the Game
              (<strong>the "Game Client"</strong>);
            </li>
            <li>
              The Dauntless website (
              <strong>
                <a href="https://do-heart.com" target="_">
                  www.do-heart.com
                </a>
              </strong>
              ) (<strong>the "Site"</strong>); and
            </li>
            <li>
              The Dragonheart online FanPage : www.facebook.com/doheartss (<strong>the "FanPage"</strong>).
            </li>
          </ol>
          <p>
            To make these Terms easier to read, the Website includes the FanPage and “Services” means the Game, Game Client, and Website.
            Please read these Terms and our Privacy Policy carefully because they govern your use of our Services.
          </p>
          <p className="text-black text-base md:text-xl text-center mt-16 font-medium">AGREEMENT TO TERMS</p>
          <p>
            By using our Services, you agree to be bound by these Terms. If you don’t agree to be bound by these Terms, do not use the
            Services.
          </p>
          <p className="text-black text-base md:text-xl text-center mt-16 font-medium">PRIVACY STATEMENT</p>
          <p>
            Please refer to our Privacy Policy at{" "}
            <a href="https://do-heart.com/privacy-statement" target="_">
              https://do-heart.com/privacy-statement
            </a>{" "}
            for information on how we collect, use and disclose information from our users. You acknowledge and agree that your use of the
            Services is subject to our Privacy Policy.
          </p>
          <p className="text-black text-base md:text-xl text-center mt-16 font-medium">CHANGES TO TERMS OR SERVICES</p>
          <p>
            We may modify the Terms at any time, in our sole discretion. If we do so, we’ll let you know either by posting the modified
            Terms on the Site or through other communications. It’s important that you review the Terms whenever we modify them because if
            you continue to use the Services after we have posted modified Terms on the Site, you are indicating to us that you agree to be
            bound by the modified Terms. If you don’t agree to be bound by the modified Terms, then you may not use the Services anymore.
            Because our Services are evolving over time we may change or discontinue all or any part of the Services, at any time and
            without notice, at our sole discretion.
          </p>
          <p className="text-black text-base md:text-xl text-center mt-16 font-medium">WHO MAY USE THE SERVICES</p>
          <p>
            <strong>ELIGIBILITY</strong>
            <br></br> The Services are intended solely for persons who are 13 or older. Any access to or use of the Services by anyone under
            13 is expressly prohibited. By accessing or using the Services you represent and warrant that you are 13 or older.
          </p>
          <p>
            <strong>REGISTRATION AND YOUR INFORMATION</strong>
            <br></br> If you want to use certain features of the Services you’ll have to create an account (“Account”) via the Site. It’s
            important that you provide us with accurate, complete and up-to-date information for your Account and you agree to update such
            information, as needed, to keep it accurate, complete and up-to-date. If you don’t, we might have to suspend or terminate your
            Account. You agree that you won’t disclose your Account password to anyone and you’ll notify us immediately of any unauthorized
            use of your Account. You’re responsible for all activities that occur under your Account, whether or not you know about them.
          </p>
          <p>
            <strong>FEEDBACK</strong>
            <br></br> We welcome feedback, comments and suggestions for improvements to the Services (“Feedback”). You can submit Feedback
            by emailing us at [email protected] or inbox to Fanpage : www.facebook.com/doheartss. You grant to us a non-exclusive,
            worldwide, perpetual, irrevocable, fully-paid, royalty-free, sublicensable and transferable license under any and all
            intellectual property rights that you own or control to use, copy, modify, create derivative works based upon and otherwise
            exploit the Feedback for any purpose.{" "}
          </p>
          <p className="text-black text-base md:text-xl text-center mt-16 font-medium">PURCHASES</p>
          <p>
            You may have the option to purchase in-Game currency (“Virtual Currency”) to use for acquisition of Virtual Goods(as further
            described below). We may change the Virtual Currency due for Virtual Goods and any other fees we charge at any time in our sole
            discretion. We, or our third-party payment processing provider(s), may charge your Account or credit card for such purchases.
            You agree to pay all applicable charges assessed to your Account.
          </p>
          <p>
            <strong>VIRTUAL GOODS</strong>
            <br></br> While the general use of the Services is free of charge, certain digital features such as character skins and game
            enhancements (“Virtual Goods”) may only be obtained through the use Virtual Currency. Regardless of how your Virtual Currency or
            Virtual Items (collectively “Virtual Goods”) are acquired or any terminology used, your Virtual Goods have no cash value and,
            unless otherwise required by law or permitted by these Terms, are not redeemable or refundable for any “real world” money or
            anything of monetary value. Virtual Goods are digital items only. DragonHeart grants to you a limited, non-exclusive ,
            non-sublicenseable, revocable right and license to use such Virtual Goods solely within the Game for your personal and
            non-commercial entertainment use and for no other purpose. Except for the limited rights described herein, you have no property
            interest or right or title in or to any such Virtual Goods, which remain the exclusive property of DragonHeart. Virtual Goods
            are made available to you at our sole discretion. The existence of a particular offer of Virtual Goods is not a commitment by
            DragonHeart to maintain or continue to make the Virtual Goods available in the future. The scope, variety and type of Virtual
            Goods that you may obtain can change or be discontinued at any time. DragonHeart has the absolute right to manage, regulate,
            control, modify or eliminate such Virtual Goods as it sees fit in its sole discretion, and won’t be liable to you or anyone for
            the exercise of such rights. <br />
            Except where explicitly authorized within the Game, (i) transfers of Virtual Goods are strictly prohibited; (ii) outside of the
            Game, you may not buy or sell any Virtual Goods for “real world” money or otherwise exchange items for value; and (iii)
            DragonHeart does not recognize any such purported transfers of Virtual Goods, nor the purported sale, gift or trade in the “real
            world” of anything that appears in the Game. Any attempt to do any of the foregoing is in violation of these Terms and will
            result in an automatic termination of your rights to use the Virtual Goods and may result in termination of your Account. All
            Virtual Goods are forfeited if your Account is terminated or suspended for any reason, or if we discontinue providing the
            Services.
          </p>
          <p>
            <strong>PAYMENT</strong>
            <br></br> In the event you choose to use paid aspects of the Services (e.g., if you decide to purchase Virtual Currency), you
            agree to the pricing, payment and billing policies applicable to such fees and charges, as posted in the Game. All fees are
            non-refundable and non-transferable except as expressly provided in these Terms.
          </p>
          <p>
            <strong>RETURN</strong>
            <br></br> We do not issue refunds for digital products once the order is submitted. We recommend contacting us for assistance if
            you experience any issues receiving or using our products. When submit any payment, you agree that you will not dispute, ask for
            a partial refund, or a full refund. IF YOU DO NOT AGREE TO THE RETURN POLICY, DO NOT SUBMIT ANY PAYMENT.
          </p>
          <p className="text-black text-base md:text-xl text-center mt-16 font-medium">
            GENERAL PROHIBITIONS AND DRAGON HEART ENFORCEMENT RIGHTS
          </p>
          <p>
            <strong>PLAY NICE AND PLAY FAIR</strong>
            <br></br>
            We love games and we know you do too. To keep our community and the games fun and welcoming for everyone there are some things
            you’re not allowed to do. Don’t use language that is defamatory, obscene, pornographic, vulgar or offensive, promotes
            discrimination, bigotry, racism, hatred, harassment, bullying or harm against any individual or group, or is violent or
            threatening or promotes violence or actions that are threatening to any person or entity. Do not cheat in the Games or interfere
            with or modify our Services, including through bots, cheats, hacks, exploits, mods, local memory (RAM) monitoring, or any
            software that doesn’t come from Dragon Heart and is capable of enabling cheating or otherwise interfering or modifying the
            Services or any of the Games. In addition, you agree not to do any of the following:
          </p>
          <ol>
            <li>
              Disrupt the flow of communication through “chat spamming”, whether through live communications in-Game or in posts or topics
              on the Forum;
            </li>
            <li>
              Select an Account name or user name that suggests an affiliation with Dragon Heart or any other company, contains
              personally-identifying information of you or any other Account-holder, is offensive, vulgar, obscene, sexually explicit, or is
              racially, ethnically or otherwise objectionable;
            </li>
            <li>Log into or otherwise using another person’s account;</li>
            <li>Disrupt other players’ enjoyment of the Services through action or inaction (e.g., “trolling”);</li>
            <li>
              Use, display, mirror or frame the Services or any individual element within the Services, Dragon Heart’ name, any Dragon Heart
              trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page, without
              Dragon Heart’ express written consent;
            </li>
            <li>
              Access, tamper with, or use non-public areas of the Services, Dragon Heart’ computer systems, or the technical delivery
              systems of Dragon Heart’ providers;
            </li>
            <li>
              Attempt to probe, scan or test the vulnerability of any Dragon Heart system or network or breach any security or
              authentication measures;
            </li>
            <li>
              Avoid, bypass, remove, deactivate, impair, descramble or otherwise circumvent any technological measure implemented by Dragon
              Heart or any of Dragon Heart’ providers or any other third party (including another user) to protect the Services or Content;
            </li>
            <li>
              Attempt to access or search the Services or Content or download Content from the Services through the use of any engine,
              software, tool, agent, device or mechanism (including spiders, robots, crawlers, data mining tools or the like) other than the
              software and/or search agents provided by Dragon Heart or other generally available third-party web browsers;
            </li>
            <li>
              Send any unsolicited or unauthorized advertising, promotional materials, email, junk mail, spam, chain letters or other form
              of solicitation;
            </li>
            <li>
              Use any meta tags or other hidden text or metadata utilizing a Dragon Heart trademark, logo URL or product name without Dragon
              Heart’ express written consent;
            </li>
            <li>
              Use the Services or Content, or any portion thereof, for any commercial purpose or for the benefit of any third party or in
              any manner not permitted by these Terms;
            </li>
            <li>
              Forge any networking packets or any part of the header information in any email or newsgroup posting, or in any way use the
              Services or Content to send altered, deceptive or false source-identifying information;
            </li>
            <li>
              Interfere with, or attempt to interfere with, the access of any user, host or network, including, without limitation, sending
              a virus, overloading, flooding, spamming, or mail-bombing the Services;
            </li>
            <li>
              Collect or store any personally identifiable information from the Services from other users of the Services without their
              express permission;
            </li>
            <li>Impersonate or misrepresent your affiliation with any person or entity;</li>
            <li>Violate any applicable law or regulation; or</li>
            <li>Encourage or enable any other individual to do any of the foregoing.</li>
          </ol>
        </div>
        <div className="pb-16"></div>
      </div>
    </div>
  );
};

export default Terms;
