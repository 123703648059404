import { axiosInstance } from "./axios";
class PetService {
  async getListPetByCharacter(char_id) {
    return axiosInstance
      .get(`/pets/list-pet-by-character?char_id=${char_id}`)
      .then((response) => {
        const data = response.data;
        return data;
      })
      .catch((error) => {
        return [];
      });
  }
  upgradePetBoss(id, aid) {
    const data = {
      pet_id: id,
      pet_aid: aid,
    };
    return axiosInstance.post("/pets/upgrade-pet", data);
  }
}

export default new PetService();
