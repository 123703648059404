import React from "react";

import News from "./News";
import Classes from "./Classes";
import SlideShow from "./SlideShow";
import Right from "../Sidebar/Right";
import BottomFooter from "../Footer/BottomFooter";

//images and video

import bg from "../../images/bg.jpg";
import video from "../../images/kv-video.mp4";
import slogan from "../../images/home/slogan.png";
import buttonDownload from "../../images/buttons/download.png";

import { Link } from "react-router-dom";

//styled components
import styled from "styled-components";

const Home = () => {
  // scroll
  // const [scroll, setScroll] = useState(false);
  // function updatePosition() {
  //    if (window.pageYOffset > 150) {
  //       setScroll(true);
  //    } else {
  //       setScroll(false);
  //    }
  // }
  // //effect
  // useLayoutEffect(() => {
  //    window.addEventListener("scroll", updatePosition);
  //    updatePosition();
  //    return () => window.removeEventListener("scroll", updatePosition);
  // }, []);

  return (
    <>
      <Right />
      {/* <div className="petal absolute-center">
        <img src={`${petal}`} alt="petal" className="cover" />
      </div> */}
      <BackgroundVideo>
        <video autoPlay loop muted>
          <source src={video} type="video/mp4" className="cover" />
        </video>
        <Slogan>
          <img src={`${slogan}`} alt="slogan" className="cover" />
        </Slogan>
        <div className="absolute top-2/3 flex flex-wrap z-10">
          <Link
            to="/download"
            className="hover:opacity-90"
          >
            {/* <GoIcons.GoDesktopDownload className="text-3xl md:text-5xl mr-2" />
            <span className="text-text-brown font-semibold text-xs md:text-sm lg:text-base">
              Download for PC <p className="text-xs md:text-sm lg:text-base text-center"></p>
            </span> */}
            <img src={buttonDownload} alt="btn download"/>
          </Link>
          {/* <Link
            to="/noeffect-download"
            className="px-4 py-4 md:px-6 md:py-6 bg-gradient-to-r from-brown  to-text-download rounded-lg flex items-center text-brown hover:opacity-90"
          >
            <span className="text-text-brown font-semibold text-xs md:text-sm lg:text-base">
              Download for PC <p className="text-xs md:text-sm lg:text-base text-center">(No Effect)</p>
            </span>
            <GoIcons.GoDesktopDownload className="text-3xl md:text-5xl ml-2" />
          </Link> */}
        </div>

        {/* <CloudBackgroundBefore>
          <img src={`${bf_bg}`} alt="bg" className="cover" />
        </CloudBackgroundBefore> */}
        {/* <div className="bg-blur"></div> */}
      </BackgroundVideo>
      <div className="h-full w-full relative">
        <img src={bg} alt="bf-bg" className="h-full w-full absolute" />
        <News />
        <Classes />
        <SlideShow />
      </div>
      <BottomFooter />
    </>
  );
};

const BackgroundVideo = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  scroll-snap-align: start;
`;

const Slogan = styled.div`
  width: 65%;
  height: auto;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 10%;
`;

// const CloudBackgroundBefore = styled.div`
//   position: absolute;
//   bottom: 0;
//   height: auto;
//   width: 100%;
//   margin: 0 auto;
// `;
export default Home;
