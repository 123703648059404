import React from "react";
import styled from "styled-components";

//import ButtonMore from "./ButtonMore";

import { formatDate } from "../../../helpers/helpers";
const NewsInfo = ({ newsInfo }) => {
  if (!newsInfo) {
    return <></>;
  }
  return (
    <WrapNewsInfo>
      {newsInfo.map((item, index) => {
        return (
          index < 7 && (
            <FNewsContent key={`info-news-${index}`}>
              <span className="news-badges margin-left-8 font-weight-500">{item.post_type}</span>
              <a
                className="news-title margin-left-24 font-weight-400 truncate"
                href={`/posts/${item.post_type}-${item.id}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.post_title}
              </a>
              <span className="news-date">{formatDate(item.post_date)}</span>
            </FNewsContent>
          )
        );
      })}
    </WrapNewsInfo>
  );
};
const WrapNewsInfo = styled.div`
  position: relative;
  width: 85%;
  height: 100%;
  min-height: 40vh;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.4);
  border-radius: 10px;
  border-right: 4px solid #e1e1e0;
  border-bottom: 4px solid #e1e1e0;
  margin-left: 4px;
  padding-bottom: 12px;
`;
const FNewsContent = styled.div`
  display: flex;
  align-items: center;
  padding-top: 3%;

  font-size: 1vw;
  .news-badges {
    color: #439fb8;
    border-right: 1px solid #439fb8;
    width: 10%;
  }
  .news-title {
    flex-wrap: wrap;
    width: 65%;
    color: #555;
    cursor: pointer;
    min-width: 65%;
  }
  .news-date {
    color: #555;
    width: 18%;
  }
`;
export default NewsInfo;
