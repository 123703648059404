import React, { useState, useEffect } from "react";
import _ from "lodash";
import styled from "styled-components";

//toast
import toaster, { Toaster } from "react-hot-toast";

//image
import img_pmw from "../../images/paymentwall.png";
import img_pp from "../../images/paypal.png";
import pkg1 from "../../images/buttons/package1usd.png";
import pkg10 from "../../images/buttons/package10usd.png";
import pkg100 from "../../images/buttons/package100usd.png";

//redux
import { useSelector } from "react-redux";

//router
import { Redirect } from "react-router-dom";

import Paymentwall from "paymentwall";
import CPaymentwall from "./Paymentwall";
import Promotion from "./Promotion";

//profile sidebar
import ProfileSidebar from "../Sidebar/ProfileSidebar";

//images
import bg from "../../images/bg1-4.jpg";

// const initialOptions = {
//   "client-id": process.env.REACT_APP_PAYPAL_CLIENT,
//   currency: "USD",
//   intent: "capture",
// };

const payments = [
    {
        id: "heart1",
        title: "Dragon Heart 1",
        price: 1,
        img: pkg1,
    },
    {
        id: "heart2",
        title: "Dragon Heart 2",
        price: 10,
        img: pkg10,
    },
    {
        id: "heart3",
        title: "Dragon Heart 3",
        price: 100,
        img: pkg100,
    },
];

const Recharge = () => {
    // token
    const isLogin = useSelector((state) => state.userReducer.isLogin);

    const [order, setOrder] = useState({});
    const [price, setPrice] = useState(0);
    const [quantity, setQuantity] = useState(1);
    const [payment, setPayment] = useState([]);
    const [paymentwallUrl, setPaymentwallUrl] = useState("");
    const [mobiamoUrl, setMobiamoUrl] = useState("");
    const [accept, setAccept] = useState(false);
    const [bill, setBill] = useState({});
    const [showSideBar, setShowSideBar] = useState(true);

    const userProfile = useSelector((state) => state.userReducer.user);
    const promotions = useSelector((state) => state.payReducer.promotions);

    const addOrder = (item) => {
        const parse = JSON.parse(item);
        const paypalItem = {
            name: parse.title,
            description: parse.title,
            sku: parse.id,
            unit_amount: {
                currency_code: "USD",
                value: parse.price + "",
            },
            tax: {
                currency_code: "USD",
                value: "0.00",
            },
            quantity: quantity + "",
        };

        setOrder(parse);
        setPayment([paypalItem]);
        setPrice(parse.price);
    };

    const onChangeQuantity = (quantity) => {
        if (!_.isEmpty(payment)) {
            quantity = parseInt(quantity);
            setQuantity(quantity);
            const oPayment = payment[0];
            oPayment.quantity = quantity + "";
            setPayment([oPayment]);
        } else {
            toaster.error("Please choose a card type", { duration: 8000 });
        }
    };
    const checkPackage = () => {
        if (_.isEmpty(order)) {
            toaster.error("Please choose a card type", { duration: 8000 });
            return false;
        }
        if (accept === false) {
            toaster.error("Please agree to our policy to continue", { duration: 8000 });
            return false;
        } else {
            return true;
        }
    };
    // const createOrder = (data, actions) => {
    //   return actions.order.create({
    //     intent: "CAPTURE",
    //     application_context: {
    //       return_url: "https://dh3.do-heart.com/recharge",
    //       cancel_url: "https://dh3.do-heart.com/recharge",
    //       brand_name: "DRAGON HEART",
    //       locale: "en-US",
    //       landing_page: "BILLING",
    //       shipping_preference: "NO_SHIPPING",
    //       user_action: "CONTINUE",
    //     },
    //     purchase_units: [
    //       {
    //         reference_id: "PUHF",
    //         description: "Dragon Heart Card",

    //         custom_id: "Dragon Heart",
    //         soft_descriptor: "Dragon Heart Card",
    //         amount: {
    //           currency_code: "USD",
    //           value: price * quantity + ".00",
    //           breakdown: {
    //             item_total: {
    //               currency_code: "USD",
    //               value: price * quantity + ".00",
    //             },
    //             shipping: {
    //               currency_code: "USD",
    //               value: "0.00",
    //             },
    //             handling: {
    //               currency_code: "USD",
    //               value: "0.00",
    //             },
    //             tax_total: {
    //               currency_code: "USD",
    //               value: "0.00",
    //             },
    //             shipping_discount: {
    //               currency_code: "USD",
    //               value: "0",
    //             },
    //           },
    //         },
    //         items: payment,
    //       },
    //     ],
    //   });
    // };

    // const onApprove = (data, actions) => {
    //   return PaymentService.createPayment({ data, payment }).then(function (response) {
    //     toaster.success(response.message, { duration: 8000 });
    //   });
    // };

    const createOrderPaymentwall = () => {
        const billDetail = payment[0];
        billDetail.username = userProfile.username;
        billDetail.price = price;

        Paymentwall.Configure(
            Paymentwall.Base.API_GOODS,
            process.env.REACT_APP_PAYMENTWALL_KEY,
            process.env.REACT_APP_PAYMENTWALL_SECRET
        );
        const widget = new Paymentwall.Widget(
            userProfile.username + `@${userProfile.server}`, // uid
            "p1", // widget
            [
                new Paymentwall.Product(
                    quantity + "|" + order.id, // ag_external_id
                    order.price * quantity, // amount
                    "USD", // currencycode
                    quantity + "x" + order.title, // ag_name
                    Paymentwall.Product.TYPE_FIXED // ag_type
                ),
            ],
            {
                email: userProfile.email,
                ps: "all", // Replace it with specific payment system short code for single payment methods
                success_url: window.location.href,
            }
        );
        setBill(billDetail);
        setPaymentwallUrl(widget.getUrl());
    };

    // const createOrderMobiamo = () => {};
    useEffect(() => {
        if (isLogin) {
            Paymentwall.Configure(
                Paymentwall.Base.API_VC,
                process.env.REACT_APP_PAYMENTWALL_MOBIAMO_KEY,
                process.env.REACT_APP_PAYMENTWALL_MOBIAMO_SECRET
            );
            const widget = new Paymentwall.Widget(
                userProfile.username + `@${userProfile.server}`, // uid
                "mo1_1", // widget
                [],
                {
                    email: userProfile.email,
                    ps: "mobilegateway",
                    vcr: 346,
                    currency: "USD",
                    success_url: window.location.href,
                }
            );
            setMobiamoUrl(widget.getUrl());
        }
    }, [userProfile, isLogin]);

    if (!isLogin) {
        return <Redirect to="/login" />;
    }
    return (
        <ProfileContainer>
            <img src={bg} alt="background-comm" className="cover img-absolute" />
            {/* //profile sidebar */}
            <ProfileSidebar
                show={showSideBar}
                onShow={() => {
                    setShowSideBar(!showSideBar);
                }}
            />
            <div className="max-w-8xl w-full h-screen mx-auto overflow-scroll">
                <Toaster
                    position="top-center"
                    reverseOrder={true}
                    toastOptions={{ style: { fontSize: 14 } }}
                />

                {/* //rules */}
                <div className="max-w-6xl mx-auto mt-12 space-y-1 bg-gray-50 rounded-md">
                    <p
                        className="bg-button-green py-4 text-gray-200 font-semibold uppercase 
        text-center rounded-t-md text-md md:text-lg lg:text-2xl"
                    >
                        Recharge Rules
                    </p>
                    <div className="px-8 py-4 space-y-1 text-gray-700 text-xs md:text-sm lg:text-base">
                        <p>
                            We <span className="text-red-500 font-semibold">do not</span>{" "}
                            <span className="font-semibold">
                                issue refunds for digital products once the order is submitted
                            </span>
                        </p>
                        <p>
                            We recommend{" "}
                            <span className="font-semibold">contacting us for assistance</span> if
                            you experience any issues receiving or using our products.
                        </p>
                        <p>
                            By buying our product from our website you agree that, because of the
                            nature of the products sold Which are digital,{" "}
                            <span className="font-semibold text-red-500">
                                there are no refunds under any circumstance.
                            </span>
                        </p>
                        <p>
                            If you submit any payment, you agree that you{" "}
                            <span className="font-semibold">
                                will not dispute, ask for a partial refund, or a full refund.
                            </span>
                        </p>
                        <p>
                            <span className="font-semibold">IF YOU DO NOT AGREE TO THE ABOVE</span>{" "}
                            <span className="font-semibold text-red-500">
                                DO NOT SUBMIT ANY PAYMENT.
                            </span>
                        </p>
                        <hr></hr>
                        <p className="font-semibold">
                            You will receive Silver(s) after payment completed.
                        </p>
                    </div>
                </div>
                {/* end rules */}

                {/* promotion exchange rate */}
                <div className="max-w-6xl mx-auto mt-12 rounded-md">
                    <p
                        className="bg-button-green py-4 text-gray-200 font-semibold uppercase 
        text-center rounded-t-md text-md md:text-lg lg:text-2xl"
                    >
                        Exchange rate
                    </p>
                    <Promotion promotions={promotions} />
                </div>
                {/* end promotion */}

                {/* // payment */}
                <div className="max-w-6xl mx-auto mt-12 bg-gray-50 rounded-md">
                    <p
                        className="bg-button-green py-4 text-gray-200 font-semibold uppercase 
        text-center rounded-t-md text-md md:text-lg lg:text-2xl"
                    >
                        Recharge for <span className="text-yellow-300">{userProfile.server_name}</span>
                    </p>
                    <div className="px-8 py-4 text-gray-700 text-xs md:text-sm lg:text-base space-y-8">
                        <div className="w-4/5 mx-auto flex gap-2 md:gap-4 lg:gap-8">
                            {payments.flatMap((payment, index) => {
                                return (
                                    <div
                                        className={`flex flex-col flex-1 h-auto rounded-md text-center
               transform hover:scale-110 motion-reduce:transform-none
               cursor-pointer shadow-md border ${payment.id === order.id && "border-blue-600"}`}
                                        key={index}
                                        onClick={() => addOrder(JSON.stringify(payment))}
                                    >
                                        <img
                                            src={payment.img}
                                            alt="logo"
                                            className="object-cover"
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        <div className="flex w-1/4 mx-auto border border-green-900 outline-none rounded-md text-green-900 flex-col relative">
                            <span className="absolute px-2 pt-1" style={{ fontSize: 10 }}>
                                Quantity
                            </span>
                            <input
                                type="number"
                                value={quantity}
                                min={1}
                                className="h-full w-full px-2 pt-5 pb-2 rounded-md"
                                onChange={(e) => onChangeQuantity(e.target.value)}
                            />
                        </div>

                        <div className="text-center space-x-1 flex items-center justify-center">
                            <input
                                type="checkbox"
                                className="h-6 w-6"
                                onChange={() => setAccept(!accept)}
                                value={accept}
                            />
                            <label>
                                By continuing purchase , I've read and agree to the{" "}
                                <a
                                    href="/terms-of-use"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="font-semibold text-blue-700"
                                >
                                    Terms of Service
                                </a>{" "}
                                and{" "}
                                <a
                                    href="/privacy-statement"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="font-semibold text-blue-700"
                                >
                                    Privacy Policy
                                </a>
                            </label>
                        </div>

                        <div className="flex space-y-4">
                            {/* paypal button */}
                            {/* <PayPalButton createOrder={(data, actions) => createOrder(data, actions)} onApprove={(data, actions) => onApprove(data, actions)} /> */}
                            {/* <div className="w-1/3 mx-auto">
                <PayPalScriptProvider options={initialOptions}>
                  <PayPalButtons
                    // onApprove={(data, actions) => onApprove(data, actions)}
                    // createOrder={(data, actions) => createOrder(data, actions)}
                    style={{ height: 45 }}
                    forceReRender={[price, quantity]}
                    // disabled={_.isEmpty(order) || accept === false ? true : false}
                    className="z-0"
                    onClick={() => checkPackage() && createOrderPaymentwall()}
                  />
                </PayPalScriptProvider>
              </div> */}
                            <button
                                className="w-1/2 h-auto flex justify-center items-center rounded-md cursor-pointer mx-auto"
                                onClick={() => checkPackage() && createOrderPaymentwall()}
                            >
                                <img src={img_pp} alt="paypal" />
                            </button>

                            {/* payment wall button */}
                            <button
                                className="w-1/3 h-14 flex justify-center items-center rounded-md cursor-pointer mx-auto"
                                onClick={() => checkPackage() && createOrderPaymentwall()}
                            >
                                <img src={img_pmw} alt="paymentwall" />
                            </button>
                        </div>
                    </div>
                </div>
                {paymentwallUrl !== "" && (
                    <CPaymentwall
                        url={paymentwallUrl}
                        onClose={() => setPaymentwallUrl("")}
                        detail={bill}
                    />
                )}
                {/* //end payment */}

                {/* mobiamo */}
                <div className="max-w-6xl mx-auto mt-12 rounded-md h-full overflow-y-hidden">
                    <p
                        className="bg-button-green py-4 text-gray-200 font-semibold uppercase 
        text-center rounded-t-md text-md md:text-lg lg:text-2xl"
                    >
                        Recharge with mobiamo
                    </p>
                    <iframe
                        title="Dragon Heart Pingpack"
                        src={mobiamoUrl}
                        className="w-full h-full rounded-b-md mobiamo"
                    ></iframe>
                </div>
                {/* end mobiamo */}
            </div>
        </ProfileContainer>
    );
};

const ProfileContainer = styled.div`
    width: 100%;
    max-width: 1920px;
    height: 100vh;
    position: relative;
    margin: 0 auto;
    user-select: none;
    display: flex;
    align-items: center;
    justify-content: center;
`;
export default Recharge;
