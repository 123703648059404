import React from "react";
import styled from "styled-components";
const TextInput = (props) => {
  const { type, placeholder, onChange, iconLeft, iconRight, editable = true, value, name, onOpen } = props;
  return (
    <>
      <WrapInput>
        <IconLeft>{iconLeft}</IconLeft>
        <input
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          readOnly={!editable}
          defaultValue={value}
          name={name}
        />
        <IconRight onClick={onOpen}>{iconRight}</IconRight>
      </WrapInput>
    </>
  );
};

const WrapInput = styled.div`
  display: flex;
  width: 60%;
  align-items: center;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 0% 1%;
  margin: 1% 0;
  input[type="text"],
  [type="password"] {
    width: 100%;
    height: 100%;
    outline: none;
    border: none;
    font-size: 1rem;
    padding: 3% 1%;
    ::placeholder {
      color: #a8a7a8;
    }
  }
  @media (max-width: 414px) {
    input[type="text"],
    [type="password"] {
      font-size: 0.5rem;
    }
  }
  @media (max-width: 768px) {
    width: 90%;
    margin: 2% 0;
  }
`;
const IconLeft = styled.div`
  margin-right: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #858384;
  @media (max-width: 414px) {
    font-size: 1rem;
  }
`;
const IconRight = styled.div`
  margin-left: 1%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  color: #858384;
  @media (max-width: 414px) {
    font-size: 1rem;
  }

  &:hover {
    cursor: pointer;
  }
`;
export default TextInput;
