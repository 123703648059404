import React from "react";
import styled from "styled-components";
const NewsLink = ({ item, onActive, active }) => {
  return (
    <NewsLinkLayout onClick={onActive}>
      <span className={`${active === item.id ? "news-active" : ""}`}>
        {item.type}
      </span>
      <div className="slash"></div>
    </NewsLinkLayout>
  );
};
const NewsLinkLayout = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow: hidden;

  span {
    margin: 0px 0px;
    width: 5vw;
    padding: 4px 0px;
    border-radius: 16px;
    text-align: center;
    font-size: 1vw;
    font-weight: 400;
    cursor: pointer;
  }
  .slash {
    margin: 0px 16px;
  }
`;
export default NewsLink;
