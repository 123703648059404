const initialState = {
  data: [],
  aPosts: [],
  smallData: [],
  type: "",
  detail: {},
  slide: [],
  id: 0,
};
let initData = [];

const sevenPosts = (type) => {
  let arr = [];
  initData.forEach((element) => {
    let i = 0;
    if (i < 7) {
      if (type === element.post_type) {
        arr.push(element);
      }
      if (type === "All") {
        arr.push(element);
      }
      i++;
    }
  });
  return arr;
};
const sevenSlides = (data) => {
  let arr = [];
  data.forEach((element) => {
    let i = 0;
    if (i < 7) {
      if (element.feature_image) {
        arr.push(element);
      }
      i++;
    }
  });
  return arr;
};
export default function newsReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case "INIT-POST":
      return (initData = action.payload);
    case "INIT-SLIDE":
      return {
        ...state,
        slide: sevenSlides(action.payload),
      };
    case "POSTS":
      return {
        ...state,
        data: action.payload,
      };
    case "APOSTS":
      return {
        ...state,
        aPosts: action.payload,
      };
    case "SMALL-POSTS":
      return {
        ...state,
        smallData: sevenPosts(action.payload),
      };
    case "POST-TYPE":
      return {
        ...state,
        type: action.payload,
      };
    case "POST-ID":
      return {
        ...state,
        id: action.payload,
      };
    case "POST-DETAIL":
      return {
        ...state,
        detail: action.payload,
      };
    // Do something here based on the different types of actions
    default:
      return state;
  }
}
