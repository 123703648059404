import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import * as BsIcons from "react-icons/bs";

const ModalPet = ({ isOpen, onClose, listPet, dialogRef, upGradePet }) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-50 overflow-hidden"
        onClose={onClose}
        open={isOpen}
      >
        <div className="min-h-screen text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="inline-block h-screen align-middle" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div
              style={{ height: "100vh" }}
              className="w-full inline-flex justify-center items-start align-middle overflow-hidden bg-black bg-opacity-70"
            >
              {/* body here */}
              <div className="w-full mx-auto max-w-8xl relative">
                <div className="max-w-7xl m-auto mt-32 mb-2 md:mb-8">
                  {/*list pet */}
                  <span className="inline-flex items-center mt-4 md:mt-8 lg:mt-16">
                    <BsIcons.BsXDiamond className="text-text-download text-sm lg:text-base" />
                    <h2 className="text-sm md:text-xl lg:text-3xl pl-2 lg:pl-4 font-semibold text-text-download">
                      List Pet
                    </h2>
                  </span>
                  <div className="w-full border px-8 py-8 rounded-sm mt-2 lg:mt-4 flex flex-col">
                    {/* header patch */}
                    <div className="flex bg-gray-700 text-gray-100 py-2 text-sm md:text-base lg:text-lg justify-around">
                      <span className="w-1/12 text-center">#</span>
                      <span className=" w-1/2 flex-1 text-center">Character name</span>
                      <span className="w-1/2 flex-1 text-center ">Action</span>
                    </div>

                    {/* item list pet */}
                    {listPet.map((item, index) => {
                      return (
                        <div
                          className="flex justify-around bg-gray-200 border-b border-gray-300 py-2 text-green-900 text-sm md:text-base lg:text-lg"
                          key={index}
                        >
                          <span className="w-1/12 text-center py-2">{index + 1}</span>
                          <span className="w-1/2 flex-1 text-center py-2 ">{item.pet_name}</span>
                          <button ref={dialogRef} className="w-1/2 flex-1 text-center py-2">
                            <span
                              className="bg-green-900 p-2 text-gray-50 rounded-md text-sm"
                              onClick={() => upGradePet(item.pet_id, item.pet_aid)}
                            >
                              Upgrade
                            </span>
                          </button>
                        </div>
                      );
                    })}
                    {/* end item list Pet */}
                  </div>
                  {/* end list list Pet */}
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ModalPet;
