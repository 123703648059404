import { axiosInstance } from "./axios";
import CryptoEncrypt from "../helpers/encoder";
class PaymentService {
  async createPayment(payment) {
    const encodePayment = CryptoEncrypt.AESEncrypt(JSON.stringify(payment));
    return axiosInstance
      .post("/payment/create-payment", { data: encodePayment })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error.response;
      });
  }
  async historyPayment(limit, offset) {
    return axiosInstance
      .get(`/payment/history-payment?limit=${limit}&offset=${offset}`)
      .then((respone) => {
        const data = respone.data.data;
        let silvers = 0;
        let usd = 0;
        data.payments.forEach((element) => {
          if (element.payment_method === "Mobiamo") {
            silvers = silvers + element.cost;
          } else {
            usd = usd + element.cost;
          }
        });
        let arr2 = [];
        for (let index = 0; index < data.totalPage; index++) {
          arr2.push(index + 1);
        }
        return {
          silvers,
          usd,
          payments: data.payments,
          totalPage: arr2,
        };
      })
      .catch((error) => {
        return {};
      });
  }

  async getPayamountList() {
    return axiosInstance
      .get("/payment/pay-amount")
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return [];
      });
  }
}

export default new PaymentService();
