const initialState = {
  pets: [],
};
export default function petReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case "LIST-PET":
      return {
        ...state,
        pets: action.payload,
      };
    default:
      return state;
  }
}
