//icon image classes
import icon_mp0 from "../../../images/classes/icon_mp0.png";
import icon_mp1 from "../../../images/classes/icon_mp1.png";
import icon_mp2 from "../../../images/classes/icon_mp2.png";
import icon_mp3 from "../../../images/classes/icon_mp3.png";
import icon_mp4 from "../../../images/classes/icon_mp4.png";
import icon_mp5 from "../../../images/classes/icon_mp5.png";
import icon_mp6 from "../../../images/classes/icon_mp6.png";
import icon_mp7 from "../../../images/classes/icon_mp7.png";
import icon_mp8 from "../../../images/classes/icon_mp8.png";
import icon_mp9 from "../../../images/classes/icon_mp9.png";
import icon_mp10 from "../../../images/classes/icon_mp10.png";
import icon_mp11 from "../../../images/classes/icon_mp11.png";
import icon_mp12 from "../../../images/classes/icon_mp12.png";
import icon_mp13 from "../../../images/classes/icon_mp13.png";

// classes name image
import name_mp0 from "../../../images/classes/name_mp0.png";
import name_mp1 from "../../../images/classes/name_mp1.png";
import name_mp2 from "../../../images/classes/name_mp2.png";
import name_mp3 from "../../../images/classes/name_mp3.png";
import name_mp4 from "../../../images/classes/name_mp4.png";
import name_mp5 from "../../../images/classes/name_mp5.png";
import name_mp6 from "../../../images/classes/name_mp6.png";
import name_mp7 from "../../../images/classes/name_mp7.png";
import name_mp8 from "../../../images/classes/name_mp8.png";
import name_mp9 from "../../../images/classes/name_mp9.png";
import name_mp10 from "../../../images/classes/name_mp10.png";
import name_mp11 from "../../../images/classes/name_mp11.png";
import name_mp12 from "../../../images/classes/name_mp12.png";
import name_mp13 from "../../../images/classes/name_mp13.png";

//image classes
// female
import mp0_1 from "../../../images/classes/mp0_1.png";
import mp1_1 from "../../../images/classes/mp1_1.png";
import mp2_1 from "../../../images/classes/mp2_1.png";
import mp3_1 from "../../../images/classes/mp3_1.png";
import mp4_1 from "../../../images/classes/mp4_1.png";
import mp5_1 from "../../../images/classes/mp5_1.png";
import mp6_1 from "../../../images/classes/mp6_1.png";
import mp7_1 from "../../../images/classes/mp7_1.png";
import mp8_1 from "../../../images/classes/mp8_1.png";
import mp9_1 from "../../../images/classes/mp9_1.png";
import mp10_1 from "../../../images/classes/mp10_1.png";
import mp11_1 from "../../../images/classes/mp11_1.png";
import mp12_1 from "../../../images/classes/mp12_1.png";
import mp13_1 from "../../../images/classes/mp13_1.png";
//male
import mp0_0 from "../../../images/classes/mp0_0.png";
import mp1_0 from "../../../images/classes/mp1_0.png";
import mp2_0 from "../../../images/classes/mp2_0.png";
import mp3_0 from "../../../images/classes/mp3_0.png";
import mp4_0 from "../../../images/classes/mp4_0.png";
import mp5_0 from "../../../images/classes/mp5_0.png";
import mp6_0 from "../../../images/classes/mp6_0.png";
import mp7_0 from "../../../images/classes/mp7_0.png";
import mp8_0 from "../../../images/classes/mp8_0.png";
import mp9_0 from "../../../images/classes/mp9_0.png";
import mp10_0 from "../../../images/classes/mp10_0.png";
import mp11_0 from "../../../images/classes/mp11_0.png";
import mp12_0 from "../../../images/classes/mp12_0.png";
import mp13_0 from "../../../images/classes/mp13_0.png";

//classes intro
import intro_mp0 from "../../../images/classes/intro_mp0.png";
import intro_mp1 from "../../../images/classes/intro_mp1.png";
import intro_mp2 from "../../../images/classes/intro_mp2.png";
import intro_mp3 from "../../../images/classes/intro_mp3.png";
import intro_mp4 from "../../../images/classes/intro_mp4.png";
import intro_mp5 from "../../../images/classes/intro_mp5.png";
import intro_mp6 from "../../../images/classes/intro_mp6.png";
import intro_mp7 from "../../../images/classes/intro_mp7.png";
import intro_mp8 from "../../../images/classes/intro_mp8.png";
import intro_mp9 from "../../../images/classes/intro_mp9.png";
import intro_mp10 from "../../../images/classes/intro_mp10.png";
import intro_mp11 from "../../../images/classes/intro_mp11.png";
import intro_mp12 from "../../../images/classes/intro_mp12.png";
import intro_mp13 from "../../../images/classes/intro_mp13.png";

export const ClassesNameData = [
  {
    id: 0,
    icon_name: icon_mp0,
    image_path_female: mp0_1,
    image_path_male: mp0_0,
    image_name: name_mp0,
    intro: intro_mp0,
  },
  {
    id: 1,
    icon_name: icon_mp1,
    image_path_female: mp1_1,
    image_path_male: mp1_0,
    image_name: name_mp1,
    intro: intro_mp1,
  },
  {
    id: 2,
    icon_name: icon_mp2,
    image_path_female: mp2_1,
    image_path_male: mp2_0,
    image_name: name_mp2,
    intro: intro_mp2,
  },
  {
    id: 3,
    icon_name: icon_mp3,
    image_path_female: mp3_1,
    image_path_male: mp3_0,
    image_name: name_mp3,
    intro: intro_mp3,
  },
  {
    id: 4,
    icon_name: icon_mp4,
    image_path_female: mp4_1,
    image_path_male: mp4_0,
    image_name: name_mp4,
    intro: intro_mp4,
  },
  {
    id: 5,
    icon_name: icon_mp5,
    image_path_female: mp5_1,
    image_path_male: mp5_0,
    image_name: name_mp5,
    intro: intro_mp5,
  },
  {
    id: 6,
    icon_name: icon_mp6,
    image_path_female: mp6_1,
    image_path_male: mp6_0,
    image_name: name_mp6,
    intro: intro_mp6,
  },
  {
    id: 7,
    icon_name: icon_mp7,
    image_path_female: mp7_1,
    image_path_male: mp7_0,
    image_name: name_mp7,
    intro: intro_mp7,
  },
  {
    id: 8,
    icon_name: icon_mp8,
    image_path_female: mp8_1,
    image_path_male: mp8_0,
    image_name: name_mp8,
    intro: intro_mp8,
  },
  {
    id: 9,
    icon_name: icon_mp9,
    image_path_female: mp9_1,
    image_path_male: mp9_0,
    image_name: name_mp9,
    intro: intro_mp9,
  },
  {
    id: 10,
    icon_name: icon_mp10,
    image_path_female: mp10_1,
    image_path_male: mp10_0,
    image_name: name_mp10,
    intro: intro_mp10,
  },
  {
    id: 11,
    icon_name: icon_mp11,
    image_path_female: mp11_1,
    image_path_male: mp11_0,
    image_name: name_mp11,
    intro: intro_mp11,
  },
  {
    id: 12,
    icon_name: icon_mp12,
    image_path_female: mp12_1,
    image_path_male: mp12_0,
    image_name: name_mp12,
    intro: intro_mp12,
  },
  {
    id: 13,
    icon_name: icon_mp13,
    image_path_female: mp13_1,
    image_path_male: mp13_0,
    image_name: name_mp13,
    intro: intro_mp13,
  },
];
