import React from "react";
import styled from "styled-components";

//icons
//import * as VscIcons from "react-icons/vsc";
import * as AiIcons from "react-icons/ai";
const Loading = () => {
   return (
      <LoadingContainer>
         <AiIcons.AiOutlineLoading3Quarters size="52" className="loading" color="#ffffff" />
         <p>Loading...</p>
      </LoadingContainer>
   );
};

const LoadingContainer = styled.div`
   position: absolute;
   display: flex;
   width: 100%;
   height: 100vh;
   margin: 0 auto;
   align-items: center;
   justify-content: center;
   background-color: rgba(0, 0, 0, 0.7);
   z-index: 99999;
   flex-direction: column;
   p {
      color: #ffffff;
      margin-top: 0.5%;
   }
   transition: 350ms;
`;
export default Loading;
