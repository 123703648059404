import create from "zustand";
import _ from "lodash";

import ServerService from "../services/server.service";
import DownloadService from "../services/download.service";
import RankingService from "../services/ranking.service";
import { config } from "../constant/config";

const initialState = {
    server: null,
    list_server: [],
    download: [],
    topLevel: [],
    server_download: null
};

const getActiveServer = (listServer) => {
    for (const server of listServer) {
        if (server.status === 1) {
            return server;
        }
    }
    return listServer[0];
};
const selectServer = (slug, data) => {
    for (const server of data) {
        if (server.slug === slug) {
            return server;
        }
    }
    return data[0];
}

const getCurrentListDownload = async (set) => {
    const download = await DownloadService.getListDownload();
    set({ download: download });
}

const getCurrentListRanking = async (set) => {
    const ranking = await RankingService.getListTopLevel();
    set({ topLevel: ranking });
}

export const useZustandStore = create((set, get) => ({
    ...initialState,
    changeServer: (slug) => {
        const list_server = get().list_server;
        for (const server of list_server) {
            if (server.slug === slug) {
                set({ server: server });
                localStorage.setItem("server", server.slug);

                getCurrentListDownload(set);
                getCurrentListRanking(set);
            }
        }
    },
    changeServerDownload: (slug) => {
        const list_server = config.server_download;
        for (const server of list_server) {
            if (server.slug === slug) {
                set({ server_download: server });
                localStorage.setItem("server_download", server.slug);

                getCurrentListDownload(set);
            }
        }
    },
    getListConfigServerActive: async () => {
        const { data } = await ServerService.getListServer();

        if (!_.isEmpty(data)) {
            const server              = getActiveServer(data);
            const server_donwload     = getActiveServer(config.server_download);

            set({ list_server: data, server: server, server_download: server_donwload });

            const localServer           = localStorage.getItem("server");
            const localServerDownload   = localStorage.getItem("server_download");
            if(!localServer)
            {
                set({ server: selectServer(server.slug, data)});
                localStorage.setItem("server", server.slug);
            }
            else
            {
                set({ server: selectServer(localServer, data) });
            }

            //default server download
            if(!localServerDownload)
            {
                set({ server_download: selectServer(server_donwload.slug, config.server_download)});
                localStorage.setItem("server_download", server_donwload.slug);
            }
            else
            {
                set({ server_download: selectServer(localServerDownload, config.server_download) });
            }
        }
        return data;
    },
    getListDownload: async () => {
        await getCurrentListDownload(set);
    },

    getListRanking: async () => {
        await getCurrentListRanking(set);
    }

}));
