import React from "react";
import { Dialog } from "@headlessui/react";

//select Input
import SelectInput from "../../../Input/SelectInput";

const FormChangeQuestion = ({ onClose, onEditProfile, editProfile, questions, question, selected, setSelected }) => {
  return (
    <div
      style={{ height: "60vh" }}
      className="max-w-4xl p-6 mt-6 text-left bg-white shadow-xl rounded-2xl transition-all transform overflow-hidden"
    >
      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
        Change Question
      </Dialog.Title>
      <div style={{ width: "30rem" }} className="mt-4">
        <input
          type="text"
          placeholder="Current question"
          className="placeholder-gray-400 w-full py-4 px-2 shadow-xl"
          name="newEmail"
          autoComplete="off"
          readOnly
          defaultValue={question.qs_text}
        />
      </div>
      <div style={{ width: "30rem" }} className="mt-4">
        <input
          type="text"
          placeholder="Current Answer"
          className="placeholder-gray-400 w-full py-4 px-2 shadow-xl"
          name="oldAnswer"
          autoComplete="off"
          onChange={(value) => onEditProfile(value)}
          defaultValue={editProfile.oldAnswer}
        />
      </div>
      <div className="mt-4 w-full">
        <SelectInput questions={questions} selected={selected} setSelected={setSelected} />
      </div>
      <div style={{ width: "30rem" }} className="mt-4">
        <input
          type="text"
          placeholder="New Answer"
          className="placeholder-gray-400 w-full py-4 px-2 shadow-xl"
          name="newAnswer"
          autoComplete="off"
          onChange={(value) => onEditProfile(value)}
          defaultValue={editProfile.newAnswer}
        />
      </div>
      <div className="mt-4 flex justify-center">
        <button
          type="button"
          className="inline-flex justify-center px-8 py-4 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="inline-flex justify-center mx-2 px-8 py-4 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default FormChangeQuestion;
