import React from "react";
import { Dialog } from "@headlessui/react";
const FormChangePassword = ({ onClose, onEditProfile, editProfile }) => {
  return (
    <div className="max-w-4xl p-6 mt-6 text-left bg-white shadow-xl rounded-2xl transition-all transform overflow-hidden">
      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
        Change Password
      </Dialog.Title>

      <div style={{width:"30rem"}} className="mt-4">
        <input
          type="password"
          placeholder="Current password"
          className="placeholder-gray-400 w-full py-4 px-2 shadow-xl"
          name="oldPassword"
          autoComplete="off"
          onChange={(value) => onEditProfile(value)}
          defaultValue={editProfile.oldPassword}
        />
      </div>
      <div style={{width:"30rem"}} className="mt-4">
        <input
          type="password"
          placeholder="New password"
          className="placeholder-gray-400 w-full py-4 px-2 shadow-xl"
          name="newPassword"
          autoComplete="off"
          onChange={(value) => onEditProfile(value)}
          defaultValue={editProfile.newPassword}
        />
      </div>
      <div style={{width:"30rem"}} className="mt-4">
        <input
          type="password"
          placeholder="Confirm new password"
          className="placeholder-gray-400 w-full py-4 px-2 shadow-xl"
          name="reNewPassword"
          autoComplete="off"
          onChange={(value) => onEditProfile(value)}
          defaultValue={editProfile.reNewPassword}
        />
      </div>
      <div style={{width:"30rem"}} className="mt-4">
        <input
          type="text"
          placeholder="Current Email"
          className="placeholder-gray-400 w-full py-4 px-2 shadow-xl"
          name="oldEmail"
          autoComplete="off"
          onChange={(value) => onEditProfile(value)}
          defaultValue={editProfile.oldEmail}
        />
      </div>

      <div className="mt-4 flex justify-center">
        <button
          type="button"
          className="inline-flex justify-center px-8 py-4 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="inline-flex justify-center mx-2 px-8 py-4 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
        >
          Submit
        </button>
      </div>
    </div>
  );
};

export default FormChangePassword;
