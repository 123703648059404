import React, { useRef, useState } from "react";
import styled from "styled-components";

//images
import bg from "../../images/bg-login.jpg";

// router
import { Link, Redirect } from "react-router-dom";

//captcha google
import ReCAPTCHA from "react-google-recaptcha";

//redux
import { useDispatch } from "react-redux";
import { loading } from "../../redux/action";

//api
import UserService from "../../services/user.service";

//toast
import toaster, { Toaster } from "react-hot-toast";

//components
import SelectInput from "../Input/SelectInput";
import InputText from "../Input/InputText";
import { checkValidateEmail, checkValidPassword, checkValidUsername } from "../../helpers/helpers";

//zustand
import { useZustandStore } from "../../zstore";

const questions = [
    { qs_id: 1, qs_text: "Where did you spend your honneymoon?" },
    { qs_id: 2, qs_text: "What is your oldest cousin's name?" },
    { qs_id: 3, qs_text: "What is your youngest child's nickname?" },
    { qs_id: 4, qs_text: "Where did you meet your spouse?" },
    { qs_id: 5, qs_text: "What town was your father born in?" },
    { qs_id: 6, qs_text: "What town was your mother born in?" },
    { qs_id: 7, qs_text: "What is the first name of your oldest niece?" },
];

const Register = () => {
    const dispatch = useDispatch();
    //token
    const token = sessionStorage.getItem("token");

    //ref captcha
    const reRef = useRef();

    //zustand
    const { list_server, server, changeServer } = useZustandStore();

    const [user, setUser] = useState({ username: "", pwd: "", cfpwd: "", email: "", answer: "" });
    const [count, setCount] = useState(1);
    const [selected, setSelected] = useState(questions[0]);
    const onSubmit = async (e) => {
        e.preventDefault();
        const data = {
            username: user.username,
            pwd: user.pwd,
            cfpwd: user.cfpwd,
            email: user.email,
            answer: user.answer,
            question: selected,
        };
        //loading
        dispatch(loading(true));
        if (checkCorrect()) {
            const reCaptcha = await reRef.current.executeAsync();
            if (count < 5) {
                reRef.current.reset();
            }
            if (count >= 5) {
                const block = Date.now() / 1000 + 5 * 60;
                localStorage.setItem("_blocked", block.toString());
            }
            await UserService.register(data, reCaptcha)
                .then((response) => {
                    const { message } = response.data;
                    toaster.success(message);
                    setUser({ username: "", pwd: "", cfpwd: "", email: "", answer: "" });
                    dispatch(loading(false));
                })
                .catch((error) => {
                    if (error.response.status === 404) {
                        dispatch(loading(false));
                        return toaster.error(
                            "Network error , please try again after a few minutes"
                        );
                    }
                    const { message } = error.response.data;
                    toaster.error(message);
                    setCount(count + 1);
                    dispatch(loading(false));
                });
        } else {
            dispatch(loading(false));
        }
    };
    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        setUser({
            ...user,
            [name]: target.value,
        });
    };
    const checkCorrect = () => {
        if (!checkValidateEmail(user.email)) {
            toaster.error("Email is invalid !!!", { duration: 8000 });
            return false;
        }
        if (!checkValidUsername(user.username)) {
            toaster.error("Username must be in lower case letters", { duration: 8000 });
            return false;
        }
        if (!checkValidPassword(user.pwd)) {
            toaster.error(
                "Password must be at least eight characters, including a lowercase letter, a number and an uppercase letter",
                {
                    duration: 8000,
                }
            );
            return false;
        }
        if (user.pwd !== user.cfpwd) {
            toaster.error("Confirm password is incorrect !!!", { duration: 10000 });
            return false;
        }
        if (user.answer === "") {
            toaster.error("Please enter answer !!!");
            return false;
        }
        return true;
    };
    if (token) {
        return <Redirect to="/profile" />;
    }
    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={true}
                toastOptions={{ style: { fontSize: 14 } }}
            />
            {/* background image */}

            <WrapRegister>
                <img src={bg} alt="bg-login" className="cover img-absolute" />
                <form onSubmit={onSubmit} method="post">
                    <p className="akaya">Dragon Heart</p>
                    <div className="select">
                        {list_server.length > 0 ? (
                            <select
                                value={server?.slug}
                                onChange={(e) => changeServer(e.target.value)}
                            >
                                {list_server.map((server, index) => {
                                    return (
                                        <option value={server?.slug} key={index}>
                                            {server?.name}
                                        </option>
                                    );
                                })}
                            </select>
                        ) : null}
                    </div>
                    <InputText
                        name="username"
                        placeholder="Username"
                        onChange={(value) => handleChange(value)}
                        value={user.username}
                        type="text"
                    />
                    <InputText
                        name="pwd"
                        placeholder="Password"
                        onChange={(value) => handleChange(value)}
                        value={user.pwd}
                        type="password"
                    />
                    <InputText
                        name="cfpwd"
                        placeholder="Confirm password"
                        onChange={(value) => handleChange(value)}
                        value={user.cfpwd}
                        type="password"
                    />
                    <InputText
                        name="email"
                        placeholder="Email address"
                        onChange={(value) => handleChange(value)}
                        value={user.email}
                        type="text"
                    />
                    <SelectInput
                        questions={questions}
                        selected={selected}
                        setSelected={setSelected}
                    />
                    <InputText
                        name="answer"
                        placeholder="Answer"
                        onChange={(value) => handleChange(value)}
                        value={user.answer}
                        type="text"
                    />
                    <button type="submit" ref={reRef}>
                        Register
                    </button>
                    <WrapLoginRegister>
                        <Link to="/login">Login</Link>
                        <Link to="/forgot-password">Forgot password ?</Link>
                    </WrapLoginRegister>
                    {/* captcha */}
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        size="invisible"
                        ref={reRef}
                    />
                </form>
            </WrapRegister>
        </>
    );
};
const WrapRegister = styled.div`
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    transition: ease 350ms ease-in-out;

    form {
        width: 90%;
        max-width: 700px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 4% 2%;
        background-color: rgba(0, 0, 0, 0.15);
        align-items: center;
        border-radius: 20px;
    }

    input[type="text"],
    input[type="password"] {
        margin-top: 3%;
        padding: 16px;
        border: none;
        font-size: 16px;
        width: 100%;
        max-width: 484px;
        border-radius: 12px;
        text-indent: 1%;
        background-color: rgba(255, 255, 255, 0.4);
        color: #333;
        font-weight: 600;
    }
    input[type="text"]:focus,
    input[type="password"]:focus {
        outline: 0;
        border: 2px solid #6e6cf0;
    }
    button {
        padding: 2% 20%;
        border-radius: 8px;
        margin-top: 2%;
        font-size: 16px;
        background-color: rgba(255, 255, 255, 0.9);
    }
    p {
        font-size: 56px;
        margin-bottom: 2%;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 4px 2px #333;
    }
    @media (max-width: 768px) {
        p {
            font-size: 46px;
        }
        button {
            font-size: 14px;
        }
        input[type="text"],
        input[type="password"] {
            font-size: 14px;
        }
    }
    @media (max-width: 411px) {
        p {
            font-size: 36px;
        }
    }
`;

const WrapLoginRegister = styled.div`
    margin-top: 5%;
    width: 100%;
    display: flex;
    justify-content: space-around;

    a {
        color: #ffffff;
        opacity: 0.8;
    }
    a:hover {
        opacity: 1;
    }
`;
export default Register;
