import { axiosInstance } from "./axios";
import CryptoEncrypt from "../helpers/encoder";
class OTPService {
  createOTP(data, token, type) {
    data.token = token;
    data.type = type;
    const encodeUser = CryptoEncrypt.AESEncrypt(JSON.stringify(data));
    return axiosInstance.post("/otp/create-otp", { data: encodeUser });
  }
  verifyOTP(otp, token, type) {
    const data = {
      otp,
      token,
      type,
    };
    const encodeUser = CryptoEncrypt.AESEncrypt(JSON.stringify(data));
    return axiosInstance.post("/otp/verify-otp", { data: encodeUser });
  }
}

export default new OTPService();
