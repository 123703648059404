import img from "../../../images/home/1.png";
import img1 from "../../../images/home/2.png";
import img2 from "../../../images/home/3.png";
import img3 from "../../../images/home/4.png";
import img4 from "../../../images/home/5.png";

export const SlideData = [
  {
    id: 1,
    path: img,
  },
  {
    id: 2,
    path: img1,
  },
  {
    id: 3,
    path: img2,
  },
  {
    id: 4,
    path: img3,
  },
  {
    id: 5,
    path: img4,
  },
];
