import React, { useEffect } from "react";

import { Link, useHistory, Route, Switch, useParams } from "react-router-dom";

import _ from "lodash";

import Post from "./Post";
import { useSelector, useDispatch } from "react-redux";
import { changePostId, changePostType, loadAllPosts, getPostDetail } from "../../redux/action";
const AllPost = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams();
  const post_id = useSelector((state) => state.newsReducer.id);
  const post_type = useSelector((state) => state.newsReducer.type);
  const postData = useSelector((state) => state.newsReducer.data);
  const aPostData = useSelector((state) => state.newsReducer.aPosts);

  const setPostType = (type) => {
    let arr = [];
    dispatch(changePostType(type));
    aPostData.forEach((post) => {
      if (post.post_type === type) {
        arr.push(post);
      }
    });
    dispatch(changePostId(arr[0].id));
    dispatch(getPostDetail(arr[0]));
    dispatch(loadAllPosts(arr));
    history.push(`/posts/${arr[0].post_type}-${arr[0].id}`);
  };
  const setPostActive = (post) => {
    const parsePost = JSON.parse(post);
    dispatch(changePostId(parsePost.id));
    dispatch(getPostDetail(parsePost));
  };

  const splitType = (url) => {
    const split = url.split("-");
    return split;
  };
  useEffect(() => {
    if (!_.isEmpty(aPostData) && !_.isNil(aPostData)) {
      if (_.isNil(id)) {
        let arr = [];
        aPostData.forEach((post) => {
          if (post.post_type === "News") {
            arr.push(post);
          }
        });
        dispatch(changePostId(aPostData[0].id));
        dispatch(getPostDetail(aPostData[0]));
        dispatch(changePostType(aPostData[0].post_type));
        dispatch(loadAllPosts(arr));
        history.push(`/posts/${aPostData[0].post_type}-${aPostData[0].id}`);
      } else if (!_.isNil(id) && _.isEmpty(post_type)) {
        let arr = [];
        const urlId = splitType(id)[1];
        const urlType = splitType(id)[0];
        aPostData.forEach((post) => {
          if (post.id === parseInt(urlId)) {
            dispatch(changePostId(post.id));
            dispatch(getPostDetail(post));
            dispatch(changePostType(post.post_type));
          }
        });
        aPostData.forEach((post) => {
          if (post.post_type === urlType) {
            arr.push(post);
          }
        });
        dispatch(loadAllPosts(arr));
      }
    }
  }, [id, history, aPostData, dispatch, post_type]);
  return (
    <>
      <div className="w-full max-w-8xl h-screen m-auto overflow-hidden bg-post-bg bg-no-repeat">
        <div className="mt-20 md:mt-28 lg:mt-32 w-full h-full min-h-screen flex gap-12">
          <div className="w-40 md:w-80 flex flex-col relative ml-16">
            <div className="bg-post-com bg-no-repeat  w-28 h-28"></div>
            <div className="bg-post-com bg-no-repeat w-4 h-14 bg-day-down absolute top-24" style={{ left: "3.2rem" }}></div>
            {/* nav1 news */}
            <div
              style={{ height: 68, marginLeft: "1.75rem" }}
              className={`${
                post_type === "News" ? "bg-nav1-active" : "bg-nav1"
              } bg-post-com bg-no-repeat w-full mt-9 cursor-pointer relative`}
              onClick={() => setPostType("News")}
            ></div>
            {/* list news */}
            {post_type === "News" && (
              <ul style={{ paddingTop: 15 }} className="relative h-64 overflow-y-auto overflow-x-hidden">
                {postData.map((post, index) => {
                  return (
                    <li style={{ marginLeft: 42 }} className="h-9 w-64 relative" key={index}>
                      <div style={{ left: 15, borderRightWidth: 1 }} className="h-6 border-yellow-600 absolute -top-3"></div>
                      <Link
                        onClick={() => setPostActive(JSON.stringify(post))}
                        to={`/posts/${post.post_type}-${post.id}`}
                        className={`bg-post-com bg-no-repeat h-full pl-8 text-base overflow-hidden flex items-center bg-nav-child text-post-text-color hover:text-white hover:bg-nav-child-active ${
                          post_id === post.id && "bg-nav-child-active"
                        }`}
                      >
                        <span className="truncate  ">{post.post_title}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
            {/* end nav1 */}

            {/* nav2 guide */}
            <div
              style={{ height: 68, marginLeft: "1.9rem" }}
              className={`${
                post_type === "Guide" ? "bg-nav2-active" : "bg-nav2"
              } bg-post-com bg-no-repeat w-full mt-6 cursor-pointer relative`}
              onClick={() => setPostType("Guide")}
            >
              <div style={{ left: 27, borderRightWidth: 1 }} className="h-9 border-yellow-600 absolute -top-9"></div>
            </div>
            {/* list guide */}
            {post_type === "Guide" && (
              <ul style={{ paddingTop: 15 }} className="relative h-64 overflow-y-auto overflow-x-hidden">
                {postData.map((post, index) => {
                  return (
                    <li style={{ marginLeft: 42 }} className="h-9 w-64 relative" key={index}>
                      <div style={{ left: 15, borderRightWidth: 1 }} className="h-6 border-yellow-600 absolute -top-3 left-[15px]"></div>
                      <Link
                        onClick={() => setPostActive(JSON.stringify(post))}
                        to={`/posts/${post.post_type}-${post.id}`}
                        className={`bg-post-com bg-no-repeat h-full pl-8 text-base overflow-hidden flex items-center bg-nav-child text-post-text-color hover:text-white hover:bg-nav-child-active ${
                          post_id === post.id && "bg-nav-child-active"
                        }`}
                      >
                        <span className="truncate  ">{post.post_title}</span>
                      </Link>
                    </li>
                  );
                })}
              </ul>
            )}
            {/* end nav2 */}

            {/* nav3 notify */}
            <div
              style={{ height: 68, marginLeft: "1.9rem" }}
              className={`${
                post_type === "Notify" ? "bg-nav3-active" : "bg-nav3"
              } bg-post-com bg-no-repeat w-full mt-6 cursor-pointer relative`}
              onClick={() => setPostType("Notify")}
            >
              <div style={{ left: 27, borderRightWidth: 1 }} className="h-8 border-yellow-600 absolute -top-8"></div>
            </div>
            {/* list notify */}
            {post_type === "Notify" && (
              <ul style={{ paddingTop: 15 }} className="relative h-64 overflow-y-auto overflow-x-hidden ">
                {postData.map((post, index) => {
                  return (
                    <li style={{ marginLeft: 42 }} className="h-9 w-64 relative" key={index}>
                      <div style={{ left: 15, borderRightWidth: 1 }} className="h-6 border-yellow-600 absolute -top-3"></div>
                      <Link
                        onClick={() => setPostActive(JSON.stringify(post))}
                        to={`/posts/${post.post_type}-${post.id}}`}
                        className={`bg-post-com bg-no-repeat h-full pl-8 text-base overflow-hidden flex items-center bg-nav-child text-post-text-color hover:text-white hover:bg-nav-child-active ${
                          post_id === post.id && "bg-nav-child-active"
                        }`}
                      >
                        <span className="truncate  ">{post.post_title}</span>
                      </Link>
                    </li>
                  );
                })}
                <div
                  style={{ width: "13.4px", height: 120, marginLeft: "3.18rem", marginTop: -14 }}
                  className="bg-post-com bg-no-repeat bg-day-up"
                ></div>
              </ul>
            )}
            {/* end nav3 */}
            {/* day up */}
            {post_type !== "Notify" && (
              <div
                style={{ width: "13.4px", height: 120, bottom: "11.2rem", left: "3.2rem" }}
                className="bg-post-com bg-no-repeat bg-day-up absolute"
              ></div>
            )}
          </div>
          <div id="content" className="w-full mx-auto h-5/6 overflow-y-auto overflow-x-hidden">
            <Switch>
              <Route children={<Post />} path="/posts/:id" />;
            </Switch>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllPost;
