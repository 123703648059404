import React from 'react'
import p404 from "../../images/404.jpg"
const Page404 = () => {
    return (
        <div className="w-full min-h-screen flex justify-center">
            <img src={p404} className="w-2/4 h-screen" alt="404"/>
        </div>
    )
}

export default Page404
