const initialState = {
    topLevel: [],
  };
  export default function rankingReducer(state = initialState, action) {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
      case "TOPLEVEL":
        return {
          ...state,
          topLevel: action.payload,
        };
      default:
        return state;
    }
  }
  