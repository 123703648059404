import React from "react";
import styled from "styled-components";
import support1 from "../../images/home/sp1.png";
import support2 from "../../images/home/sp2.png";
import support3 from "../../images/home/sp3.png";
import { Link } from "react-router-dom";
import { config } from "../../constant/config";
const Support = () => {
  return (
    <SupportLayout>
      <ImgSupport to={config.new_bie_guide}>
        <img src={`${support1}`} alt="support 1" />
      </ImgSupport>
      <ImgSupport to="/home">
        <img src={`${support2}`} alt="support 1" />
      </ImgSupport>
      <ImgSupport to="/recharge">
        <img src={`${support3}`} alt="support 1" />
      </ImgSupport>
    </SupportLayout>
  );
};
const SupportLayout = styled.div`
  display: flex;
  align-items: center;
  margin-top: 24px;
  width: 80%;
`;
const ImgSupport = styled(Link)`
  /* width: 100%;
  height: 100%; */
  width: 100%;
  height: 100%;
  margin-right: 24px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  &:hover {
    transform: scale(1.1);
  }
`;
export default Support;
