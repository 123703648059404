import UserService from "../services/user.service";
import PetService from "../services/pet.service";

export const changeNewsType = (data) => {
  return {
    type: "CHANGE-NEWS-TYPE",
    payload: data,
  };
};

export const changeClassesActive = (index) => {
  return {
    type: "CHANGE-CLASSES",
    payload: index,
  };
};
export const changeGenre = (genre) => {
  return {
    type: "CHANGE-GENRE",
    payload: genre,
  };
};
export const scrollSider = (scroll) => {
  return {
    type: "SCROLL-SIDER",
    payload: scroll,
  };
};
export const scrollPosition = (position) => {
  return {
    type: "SCROLL-POSITION",
    payload: position,
  };
};
export const userLogin = (logged) => {
  return {
    type: "USER-LOGIN",
    payload: logged,
  };
};
export const userBlocked = (blocked) => {
  return {
    type: "USER-BLOCKED",
    payload: blocked,
  };
};
export const userProfile = (profile) => {
  return {
    type: "USER-PROFILE",
    payload: profile,
  };
};
export const userLogout = (logged) => {
  return {
    type: "USER-LOGOUT",
    payload: logged,
  };
};
export const loading = (loading) => {
  return {
    type: "LOADING",
    payload: loading,
  };
};
export const toastNotify = (toast) => {
  return {
    type: "TOAST",
    payload: toast,
  };
};

export const loadAllPosts = (posts) => {
  return {
    type: "POSTS",
    payload: posts,
  };
};
export const loadSmallPosts = (posts) => {
  return {
    type: "SMALL-POSTS",
    payload: posts,
  };
};
export const changePostType = (type) => {
  return {
    type: "POST-TYPE",
    payload: type,
  };
};
export const changePostId = (id) => {
  return {
    type: "POST-ID",
    payload: id,
  };
};
export const getPostDetail = (post) => {
  return {
    type: "POST-DETAIL",
    payload: post,
  };
};

export const getListPetByCharacter = async (char_id, dispatch) => {
  const listPet = await PetService.getListPetByCharacter(char_id);
  dispatch({
    type: "LIST-PET",
    payload: listPet.data,
  });
};

// Register User
export const userResetTime = (id) => (dispatch) => {
  return UserService.resetTime(id).then(
    (response) => {
      dispatch({
        type: "SET_MESSAGE",
        payload: response.data.message,
      });

      return Promise.resolve();
    },
    (error) => {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      dispatch({
        type: "SET_MESSAGE",
        payload: message,
      });

      return Promise.reject();
    }
  );
};
//chat
export const showChat = (show) => {
  return {
    type: "SHOW-CHAT",
    payload: show,
  };
};
