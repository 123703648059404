import React from "react";
import _ from "lodash";

import Right from "../Sidebar/Right";

import * as BsIcons from "react-icons/bs";
import * as SiIcons from "react-icons/si";
import * as FaIcons from "react-icons/fa";

import { formatDate } from "../../helpers/helpers";

import bg from "../../images/bg.jpg";
import { useZustandStore } from "../../zstore";
import { config } from "../../constant/config";

const Download = () => {
	//zustand
	const { server_download, changeServerDownload, download } = useZustandStore();

	if (_.isEmpty(download)) {
		return <div className="w-full mx-auto max-w-8xl min-h-screen"></div>;
	}

	return (
		<>
			<Right />
			<img src={bg} alt="bf-bg" className="h-auto w-full absolute bg-cover" />
			<div className="w-full mx-auto max-w-8xl min-h-screen mb-4 md:mb-8 relative">
				<div className="max-w-7xl m-auto mt-44">
					{/* full download */}
					<div className="flex justify-between">
						<span className="inline-flex items-center">
							<BsIcons.BsXDiamond className="text-text-download text-sm lg:text-base" />
							<h2 className="text-sm md:text-xl lg:text-3xl pl-2 lg:pl-4 font-semibold text-text-download">Full release version for {server_download?.name}</h2>
						</span>
						<div className="select">
							{config.server_download.length > 0 ? (
								<select value={server_download?.slug} onChange={(e) => changeServerDownload(e.target.value)}>
									{config.server_download.map((server, index) => {
										return (
											<option value={server.slug} key={index}>
												{server.name}
											</option>
										);
									})}
								</select>
							) : null}
						</div>
					</div>
					<div className="w-full border px-8 py-8 rounded-sm mt-2 lg:mt-4 flex flex-col">
						<a href={`${window.location.protocol}/posts/Guide-57`} className="mb-2 text-blue-700 font-semibold">
							<span className="text-red-400">[Important Guide]</span> - GAME DOWNLOAD GUIDE - Read me before download
						</a>
						<p className="w-full font-medium">This is the only full version, to download the game please select the link below</p>
						<hr className="my-4"></hr>
						<div className="w-full gap-4 flex">
							{download.full.google.map((google, index) => {
								return (
									<a
										href={google.link}
										target="_blank"
										rel="noopener noreferrer"
										className="px-4 py-2 bg-button-green rounded-sm text-center inline-flex items-center hover:opacity-80"
										key={index}>
										<FaIcons.FaGoogleDrive className="text-gray-100 text-md" />
										<div>
											<span className="pl-2 text-xs md:text-base text-gray-100">Download on Google Drive ({index + 1})</span>
											<p className="text-sm text-gray-100">({google.version})</p>
										</div>
									</a>
								);
							})}
							{download.full.mega.map((mega, index) => {
								return (
									<a
										key={index}
										href={mega.link}
										target="_blank"
										rel="noopener noreferrer"
										className="px-4 py-2 w-52 md:w-64 rounded-sm text-center  inline-flex items-center bg-red-100 hover:opacity-80">
										<SiIcons.SiMega className="text-red-500 text-md" />
										<div className="pl-2">
											<span className="pl-2 text-xs md:text-base text-red-900 ">Download on Mega ({index + 1})</span>
											<p className="text-sm text-red-900">({mega.version})</p>
										</div>
									</a>
								);
							})}
						</div>
					</div>

					{/* update path */}
					<span className="inline-flex items-center mt-4 md:mt-8 lg:mt-16">
						<BsIcons.BsXDiamond className="text-text-download text-sm lg:text-base" />
						<h2 className="text-sm md:text-xl lg:text-3xl pl-2 lg:pl-4 font-semibold text-text-download">Updates</h2>
					</span>
					<div className="w-full border px-8 py-8 rounded-sm mt-2 lg:mt-4 flex flex-col">
						<p className="w-full font-medium">This is the latest patch released</p>
						<hr className="my-4"></hr>
						<p className="w-full font-medium mb-2 md:mb-4 text-green-900 text-base md:text-xl lg:text-2xl">Latest Patch {download.patch.latest?.version}</p>
						<div className="flex">
							{download.patch.latest?.link && (
								<div className="text-center">
									<a
										href={download.patch.latest.link}
										target="_blank"
										rel="noopener noreferrer"
										className="px-4 py-2 bg-blue-500 rounded-sm text-center inline-flex items-center hover:opacity-80">
										<FaIcons.FaCloudDownloadAlt className="text-gray-100" />
										<span className="pl-2 text-xs md:text-base text-gray-100">Patch (Effect)</span>
									</a>
								</div>
							)}
							{download.patch.latest?.link_no_effect && (
								<div className="space-x-4">
									<a
										href={download.patch.latest.link_no_effect}
										target="_blank"
										rel="noopener noreferrer"
										className="px-4 py-2 bg-yellow-600 rounded-sm text-center inline-flex items-center hover:opacity-80">
										<FaIcons.FaCloudDownloadAlt className="text-gray-100" />
										<span className="pl-2 text-xs md:text-base text-gray-100">Patch (No Effect)</span>
									</a>
								</div>
							)}
						</div>
					</div>

					{/*list update path */}
					<span className="inline-flex items-center mt-4 md:mt-8 lg:mt-16">
						<BsIcons.BsXDiamond className="text-text-download text-sm lg:text-base" />
						<h2 className="text-sm md:text-xl lg:text-3xl pl-2 lg:pl-4 font-semibold text-text-download">List Patch</h2>
					</span>
					<div className="w-full border px-8 py-8 rounded-sm mt-2 lg:mt-4 flex flex-col">
						{/* header patch */}
						<div className="flex bg-gray-700 text-gray-100 rou py-2 text-sm md:text-base lg:text-lg">
							<span className="max-w-xs w-52 text-center">Release date</span>
							<span className="max-w-xs w-52 text-center">Version</span>
							<span className="max-w-xs w-52 text-center">Download now</span>
						</div>

						{/* item patch */}
						{download.patch.list.map((item, index) => {
							return (
								index < 10 && (
									<div className="flex bg-gray-200 border-b border-gray-300 py-2 text-green-900 text-sm md:text-base lg:text-lg" key={index}>
										<span className="max-w-xs w-52 text-center px-4 py-2">{formatDate(item.date)}</span>
										<span className="max-w-xs w-52 text-center px-4 py-2">{item.version}</span>
										<a href={item.link} className="w-52 flex justify-center" target="_blank" rel="noopener noreferrer">
											<div className="flex items-center bg-blue-500 px-4 py-2 rounded-sm hover:opacity-80">
												<FaIcons.FaCloudDownloadAlt className="text-gray-100" />
												<span className="pl-2 text-xs md:text-base text-gray-100">Patch (Effect)</span>
											</div>
										</a>
										{item?.link_no_effect ? (
											<a href={item.link_no_effect} className="flex justify-center" target="_blank" rel="noopener noreferrer">
												<div className="flex items-center bg-yellow-600 px-4 py-2 rounded-sm hover:opacity-80">
													<FaIcons.FaCloudDownloadAlt className="text-gray-100" />
													<span className="pl-2 text-xs md:text-base text-gray-100">Download (No Effect)</span>
												</div>
											</a>
										) : null}
									</div>
								)
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};

export default Download;
