import React, { useState } from "react";
import styled from "styled-components";

import _ from "lodash";

import logo from "../../images/dh/logo-256.png";

import { Link } from "react-router-dom";

import * as FaIcons from "react-icons/fa";
import * as IoIcons from "react-icons/io";

//redux
import { useDispatch, useSelector } from "react-redux";
import { userLogout } from "../../redux/action";

import setAuthToken from "../../services/authToken";

const Navbar = () => {

	const dispatch = useDispatch();
	const isLogin = useSelector((state) => state.userReducer.isLogin);
	const point = useSelector((state) => state.userReducer.point);

	const [show, setShow] = useState(false);
	// onLogout
	const onLogout = () => {
		dispatch(userLogout(null));
		dispatch({ type: "GET-POINT", payload: "" });
		setAuthToken("");
		setShow(false);
	};
	return (
		<>
			<NavbarLayout>
				<div className="flex flex-col justify-center items-center gap-1 mt-6 ml-12">
					<Link to="/home" className="w-28">
						<img src={logo} alt="logo" className="cover transition duration-500 ease-in-out transform hover:-translate-x-1 hover:scale-110" />
					</Link>
				</div>
				<NavbarBurger>
					{!_.isEmpty(point) && <span className="pr-4 text-text-download font-medium text-sm md:text-base lg:text-xl opacity-0 md:opacity-100">Silvers: {point?.point}</span>}
					<FaIcons.FaBars cursor="pointer" onClick={() => setShow(true)} className="text-lg text-red-800" />
				</NavbarBurger>
			</NavbarLayout>

			<NavbarBurgerLayout show={show} className="bg-brown">
				<IoIcons.IoMdClose size="32" cursor="pointer" onClick={() => setShow(false)} className="text-red-800 mt-2 mx-2" />
				<NavbarBurgerLinks>
					{!_.isEmpty(point) && <span className="pl-4 pt-4 text-red-800 font-medium text-sm md:text-base lg:text-xl">Silvers: {point?.point}</span>}
					<NavbarBurgerLink to="/home" onClick={() => setShow(false)}>
						<FaIcons.FaHome />
						<span className="nav-icon-title">Home</span>
					</NavbarBurgerLink>
					<NavbarBurgerLink to="/ranking" onClick={() => setShow(false)}>
						<FaIcons.FaCrown />
						<span className="nav-icon-title">Ranking</span>
					</NavbarBurgerLink>
					<NavbarBurgerLink to="/recharge" onClick={() => setShow(false)}>
						<IoIcons.IoLogoBitcoin />
						<span className="nav-icon-title">Recharge</span>
					</NavbarBurgerLink>
					<NavbarBurgerLink to={isLogin ? "/profile" : "/register"} onClick={() => setShow(false)}>
						{isLogin ? <IoIcons.IoIosPerson /> : <IoIcons.IoIosPersonAdd />}
						<span className="nav-icon-title">{isLogin ? "Profile" : "Register"}</span>
					</NavbarBurgerLink>
					<NavbarBurgerLink to={isLogin ? "/" : "/login"} onClick={onLogout}>
						{isLogin ? <IoIcons.IoIosLogOut /> : <IoIcons.IoIosLogIn />}
						<span className="nav-icon-title">{isLogin ? "Logout" : "Login"}</span>
					</NavbarBurgerLink>
				</NavbarBurgerLinks>
			</NavbarBurgerLayout>
		</>
	);
};

const NavbarLayout = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;

	width: 100%;
	height: auto;
	max-width: 1920px;

	z-index: 9;
	margin: 0 auto;
`;

const NavbarBurger = styled.div`
	display: flex;
	justify-content: flex-end;
	color: #ffffff;
	padding-right: 5%;
	font-size: 1.25rem;
`;

const NavbarBurgerLayout = styled.div`
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	right: ${({ show }) => (show ? "0" : "-200%")};
	opacity: ${({ show }) => (show ? "1" : "0")};
	transition: 350ms ease-in-out;
	z-index: 99999;

	.icon-close {
		margin: 1rem 1rem;
		color: #ffffff;
	}
	@media (min-width: 768px) {
		width: 30%;
	}
	@media (min-width: 1024px) {
		width: 10%;
	}
	@media (min-width: 1025px) {
		width: 20%;
	}
`;
const NavbarBurgerLinks = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;
const NavbarBurgerLink = styled(Link)`
	text-decoration: none;
	color: rgba(153, 27, 27, 0.5);
	font-weight: 500;
	font-size: 2.5rem;
	display: inline-flex;
	align-items: center;
	padding: 0.5rem 0;
	margin: 1rem 1rem;

	.nav-icon-title {
		font-size: 1.25rem;
		margin-left: 0.5rem;
	}
	&:hover {
		opacity: 0.6;
	}
`;

export default Navbar;
