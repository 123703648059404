import React from "react";
import styled from "styled-components";
import * as IoIcons from "react-icons/io";
import { Link } from "react-router-dom";
const ButtonMore = () => {
  return (
    <BlurButton>
      <div className="blur-more"></div>
      <ButtonMoreLayout to="/posts">
        <IoIcons.IoMdAdd className="icon-more-add" />
      </ButtonMoreLayout>
    </BlurButton>
  );
};
const BlurButton = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
`;
const ButtonMoreLayout = styled(Link)`
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  background-image: linear-gradient(
    to right top,
    #6f0d0d,
    #882d28,
    #a04943,
    #b8645f,
    #cf807d,
    #d38685,
    #d88d8d,
    #dc9395,
    #cf8589,
    #c3777c,
    #b66971,
    #aa5b65
  );
  cursor: pointer;
  .icon-more-add {
    font-size: 32px;
    color: #ffffff;
  }
  @media (max-width: 1520px) {
    width: 36px;
    height: 36px;
    .icon-more-add {
      font-size: 24px;
    }
  }
  @media (max-width: 1200px) {
    width: 32px;
    height: 32px;
    .icon-more-add {
      font-size: 20px;
    }
  }
  @media (max-width: 990px) {
    width: 28px;
    height: 28px;
    .icon-more-add {
      font-size: 16px;
    }
  }
  @media (max-width: 990px) {
    width: 28px;
    height: 28px;
    .icon-more-add {
      font-size: 16px;
    }
  }
  @media (max-width: 820px) {
    width: 18px;
    height: 18px;
    .icon-more-add {
      font-size: 12px;
    }
  }
`;
export default ButtonMore;
