import React from "react";
import _ from "lodash";

import bg from "../../images/bg.jpg";

import Right from "../Sidebar/Right";

import * as BsIcons from "react-icons/bs";

//zustand
import { useZustandStore } from "../../zstore";

const Ranking = () => {
    //zustand
    const { list_server, server, changeServer, topLevel } = useZustandStore();

    if (_.isEmpty(topLevel)) {
        return <div className="w-full mx-auto max-w-8xl min-h-screen"></div>;
    }

    return (
        <>
            <Right />
            <img src={bg} alt="bf-bg" className="h-auto w-full absolute bg-cover" />
            <div className="w-full mx-auto max-w-8xl relative">
                <div className="max-w-7xl m-auto mt-32 mb-2 md:mb-8">
                    {/*list top level */}
                    <div className="inline-flex items-center mt-4 md:mt-8 lg:mt-16 w-full">
                        <div className="inline-flex items-center flex-1">
                            <BsIcons.BsXDiamond className="text-text-download text-sm lg:text-base" />
                            <h2 className="text-sm md:text-xl lg:text-3xl pl-2 lg:pl-4 font-semibold text-text-download">
                                Top Level
                            </h2>
                        </div>

                        <div className="select">
                            {list_server.length > 0 ? (
                                <select defaultValue={server?.slug} onChange={(e) => changeServer(e.target.value)}>
                                    {list_server.map((server, index) => {
                                        return (
                                            <option value={server?.slug} key={index}>
                                                {server?.name}
                                            </option>
                                        );
                                    })}
                                </select>
                            ) : null}
                        </div>
                    </div>
                    <div className="w-full border px-8 py-8 rounded-sm mt-2 lg:mt-4 flex flex-col">
                        {/* header patch */}
                        <div className="flex bg-gray-700 text-gray-100 py-2 text-sm md:text-base lg:text-lg justify-around">
                            <span className="w-1/12 text-center">#</span>
                            <span className=" w-1/2 flex-1 text-center">Character name</span>
                            <span className="w-1/2 flex-1 text-center ">Class</span>
                            <span className="w-1/2 flex-1 text-center ">Level</span>
                            <span className="w-1/2 flex-1 text-center ">Exp points</span>
                        </div>

                        {/* item top level */}
                        {topLevel.map((item, index) => {
                            return (
                                <div
                                    className="flex justify-around bg-gray-200 border-b border-gray-300 py-2 text-green-900 text-sm md:text-base lg:text-lg"
                                    key={index}
                                >
                                    <span className="w-1/12 text-center py-2">{index + 1}</span>
                                    <span className="w-1/2 flex-1 text-center py-2 ">
                                        {item.name}
                                    </span>
                                    <span className="w-1/2 flex-1 text-center py-2 ">
                                        {item.section}
                                    </span>
                                    <span className="w-1/2 flex-1 text-center py-2 ">
                                        {item.level}
                                    </span>
                                    <span className="w-1/2 flex-1 text-center py-2 truncate">
                                        {item.exp.toLocaleString("en-US")}
                                    </span>
                                </div>
                            );
                        })}
                        {/* end item top level */}
                    </div>
                    {/* end list top level */}
                </div>
            </div>
        </>
    );
};

export default Ranking;
