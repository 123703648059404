import Information from "./Profile/Information";
import HistoryRecharge from "./Profile/HistoryRecharge";
import Characters from "./Profile/Characters";

const route = [
  {
    id: "information",
    location: "/profile/information",
    title: "Information",
    component: <Information />,
  },
  {
    id: "history-recharge",
    location: "/profile/history-recharge",
    title: "History Recharge",
    component: <HistoryRecharge />,
  },
  {
    id: "characters",
    location: "/profile/characters",
    title: "Characters",
    component: <Characters />,
  },
];

export default route;
