import { axiosInstance } from "./axios";
import CryptoEncrypt from "../helpers/encoder";
class UserService {
  register(userData, token) {
    userData.token = token;
    const encodeUser = CryptoEncrypt.AESEncrypt(JSON.stringify(userData));
    const server     = localStorage.getItem("server");

    axiosInstance.defaults.headers.post["ChooseServer"] = server;
    return axiosInstance.post("/user/register", { data: encodeUser });
  }
  serviceFixAction(id, type, token) {
    const data = { id, token };
    const encodedData = CryptoEncrypt.AESEncrypt(JSON.stringify(data));
    if (type === "RESET-TIME") {
      return axiosInstance.post("/user/reset-time", { data: encodedData });
    }
    if (type === "FIX-JAM-MAP") {
      return axiosInstance.post("/user/fix-jam-map", { data: encodedData });
    }
    if (type === "FIX-MINUS-EXP") {
      return axiosInstance.post("/user/fix-minus-exp", { data: encodedData });
    }
    if (type === "UNLOCK") {
      return axiosInstance.post("/user/unlock", { data: encodedData });
    }
  }
  changePassword(data, token) {
    data.token = token;
    const encodedData = CryptoEncrypt.AESEncrypt(JSON.stringify(data));
    return axiosInstance.post("/user/change-password", { data: encodedData });
  }
  changeQuestion(data, token) {
    data.token = token;
    const encodedData = CryptoEncrypt.AESEncrypt(JSON.stringify(data));
    return axiosInstance.post("/user/change-question", { data: encodedData });
  }
  updateProfile(data, token) {
    data.token = token;
    const encodedData = CryptoEncrypt.AESEncrypt(JSON.stringify(data));
    return axiosInstance.post("/user/update-profile", { data: encodedData });
  }
  recoverPassword(data, token, type, verify) {
    data.token = token;
    data.type = type;
    const server     = localStorage.getItem("server");

    axiosInstance.defaults.headers.post["ChooseServer"] = server;
    const encodeUser = CryptoEncrypt.AESEncrypt(JSON.stringify(data));
    if (verify) {
      return axiosInstance.post("/user/verify-recover-otp", { data: encodeUser });
    }
    return axiosInstance.post("/user/recover-password", { data: encodeUser });
  }

  async getPoint() {
    return axiosInstance
      .get("/user/get-point")
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return "";
      });
  }
}

export default new UserService();
