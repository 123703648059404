const initialState = {
   scroll: false,
   position: 0,
}
export default function drawerReducer(state = initialState, action) {
   // The reducer normally looks at the action type field to decide what happens
   switch (action.type) {
      case 'SCROLL-SIDER':
         return {
            ...state,
            scroll: action.payload,
         }
      case 'SCROLL-POSITION':
         return {
            ...state,
            position: action.payload,
         }
      default:
         return state
   }
}
