const initialState = {
  route: "information",
};
export default function routeReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case "ROUTE":
      return {
        ...state,
        route: action.payload,
      };
    default:
      return state;
  }
}
