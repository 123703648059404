import { combineReducers } from "redux";

//child reducer
import newsReducer from "./reducers/news";
import classesReducer from "./reducers/classes";
import scrollReducer from "./reducers/scroll";
import userReducer from "./reducers/user";
import loadingReducer from "./reducers/loading";
import routeReducer from "./reducers/route";
import downloadReducer from "./reducers/download";
import rankingReducer from "./reducers/ranking";
import chatReducer from "./reducers/chat";
import payReducer from "./reducers/pay";
import petReducer from "./reducers/pet";
export const allReducers = combineReducers({
  newsReducer,
  classesReducer,
  scrollReducer,
  userReducer,
  loadingReducer,
  routeReducer,
  downloadReducer,
  rankingReducer,
  chatReducer,
  payReducer,
  petReducer
});
