import React, { useState } from "react";
import styled from "styled-components";

import bg_title_news from "../../images/home/page2.png";

import _ from "lodash";

// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCube, Autoplay, Pagination } from "swiper";

import NewsInfo from "./News/NewsInfo";
import Support from "./Support";
import NewsLink from "./News/NewsLink";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/effect-fade/effect-fade.scss";
import "swiper/components/effect-cube/effect-cube.scss";

import { newsData } from "./News/NewsData";
import ButtonMore from "./News/ButtonMore";

//redux
import { useDispatch, useSelector } from "react-redux";
import { loadSmallPosts } from "../../redux/action";

// //services
// import PostService from "../../services/post.service";

SwiperCore.use([EffectCube, Autoplay, Pagination]);

const News = () => {
    const dispatch = useDispatch();

    const [activeNews, setActiveNews] = useState(1);
    const newsInfo = useSelector((state) => state.newsReducer.smallData);
    const newsSlide = useSelector((state) => state.newsReducer.slide);

    const onActiveNews = async (id, type) => {
        setActiveNews(id);
        dispatch(loadSmallPosts(type));
    };
    return (
        <WrapNews>
            <div className="bg"></div>
            {/* noi chua cai swiper voi cai bang thong bao */}

            <InformationBulletin>
                <div className="mx-auto w-2/6">
                    <img src={bg_title_news} alt="bg-title-new" />
                </div>
                <BulletinContent>
                    <SlideShow>
                        {!_.isEmpty(newsSlide) && (
                            <Swiper
                                pagination={{
                                    el: ".swiper-pagination",
                                    type: "bullets",
                                }}
                                loop={true}
                                speed={1000}
                                autoplay={{
                                    delay: 4000,
                                    disableOnInteraction: false,
                                }}
                                effect="cube"
                                slidesPerView="auto"
                            >
                                {newsSlide.map((slide, index) => {
                                    return index < 5 ? (
                                        <SwiperSlide key={`slide-${index}`}>
                                            <img
                                                src={slide.feature_image}
                                                alt={`img-slide-${index}`}
                                                className="contain w-full"
                                            />
                                        </SwiperSlide>
                                    ) : null;
                                })}
                                <SwiperPagination className="swiper-pagination">
                                    <span className="swiper-pagination-bullet"></span>
                                </SwiperPagination>
                            </Swiper>
                        )}
                    </SlideShow>
                    <NewsLayout>
                        <WrapMenu>
                            <NewsMenu>
                                {newsData.map((item, index) => {
                                    return (
                                        <NewsLink
                                            item={item}
                                            key={index}
                                            onActive={() => onActiveNews(item.id, item.type)}
                                            active={activeNews}
                                        />
                                    );
                                })}
                            </NewsMenu>
                            <ButtonMore />
                        </WrapMenu>

                        {/* noi dung cua news */}
                        <NewsInfo newsInfo={newsInfo} />
                        <Support />
                    </NewsLayout>
                </BulletinContent>
            </InformationBulletin>
        </WrapNews>
    );
};
const WrapNews = styled.section`
    scroll-snap-align: start;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    padding-top: 10vh;

    .bg {
        position: absolute;
        top: 0;
        display: flex;
        flex: 1;
        width: 100%;
        max-width: 1920px;
        height: 100%;
        margin: 0 auto;
        z-index: -1;
    }
`;

const InformationBulletin = styled.div`
    width: 100%;
    height: 100%;

    p {
        text-align: center;
        font-weight: bold;
        font-size: 2.5vw;
        margin: 3% 0;
    }
`;

const BulletinContent = styled.div`
    position: relative;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(21rem, 1fr));
    grid-gap: 2vw;
    margin: 24px auto;
    padding-top: 5vh;
    /* flex-direction: row; */
`;
const SlideShow = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: auto;
    flex: 1;
    margin: 0 auto;
    flex-direction: column;
    .slide-pagination {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        height: auto;
        flex-direction: row;
    }
`;
const SwiperPagination = styled.div`
    display: flex;
    flex-direction: row;
    height: 1.2vh;
    width: 40%;
    margin: 0 auto;
    align-items: center;
`;
const NewsLayout = styled.div`
    display: flex;
    flex: 1;
    height: auto;
    width: 100%;
    flex-direction: column;
    position: relative;
    padding-bottom: 1vh;

    @media (max-width: 720px) {
        align-items: center;
        justify-content: center;
    }
`;
const WrapMenu = styled.div`
    height: auto;
    width: 80%;
    display: flex;
`;
const NewsMenu = styled.div`
    display: flex;
`;

export default News;
