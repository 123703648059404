import React, { useRef, useState } from "react";
import styled from "styled-components";

// lodash
//import _ from "lodash";

// router
import { Link, Redirect } from "react-router-dom";
//toast
import toaster, { Toaster } from "react-hot-toast";

//images
import bg from "../../images/bg-login.jpg";

//captcha google
import ReCAPTCHA from "react-google-recaptcha";

//redux
import { useDispatch, useSelector } from "react-redux";
import { loading } from "../../redux/action";

//api
import UserService from "../../services/user.service";

//helpers
import { checkValidateEmail, checkValidPassword, checkValidUsername } from "../../helpers/helpers";

//encode
import encoder from "../../helpers/encoder";

//zustand
import { useZustandStore } from "../../zstore";

const ForgotPassword = () => {
    const isLogin = useSelector((state) => state.userReducer.isLogin);
    const isBlocked = useSelector((state) => state.userReducer.blocked);
    const [verify, setVerify] = useState(false);

    const dispatch = useDispatch();
    const reRef = useRef();
    const [user, setUser] = useState({ username: "", pwd: "", cfpwd: "", email: "", otp: "" });
    const [count, setCount] = useState(1);

    //zustand
    const { list_server, server, changeServer } = useZustandStore();

    const checkCorrect = () => {
        if (!checkValidateEmail(user.email)) {
            toaster.error("Email is invalid !!!", { duration: 8000 });
            return false;
        }
        if (!checkValidUsername(user.username)) {
            toaster.error("Username must be in lower case letters", { duration: 8000 });
            return false;
        }
        if (!checkValidPassword(user.pwd)) {
            toaster.error(
                "Password must be at least eight characters, including a lowercase letter, a number and an uppercase letter",
                {
                    duration: 8000,
                }
            );
            return false;
        }
        if (user.pwd !== user.cfpwd) {
            toaster.error("Confirm password is incorrect !!!", { duration: 10000 });
            return false;
        }
        return true;
    };

    const onSubmit = async (e) => {
        e.preventDefault();
        const data = {
            username: user.username,
            pwd: encoder.MD5Encrypt(user.pwd),
            cfpwd: encoder.MD5Encrypt(user.cfpwd),
            email: user.email,
            otp: user.otp,
        };
        //loading
        dispatch(loading(true));
        const reCaptcha = await reRef.current.executeAsync();
        if (count < 5) {
            reRef.current.reset();
        }
        if (count >= 5) {
            const block = Date.now() / 1000 + 5 * 60;
            localStorage.setItem("_blocked", block.toString());
        }
        if (checkCorrect()) {
            await UserService.recoverPassword(data, reCaptcha, "recoveryPass", verify)
                .then((response) => {
                    const { message } = response.data;
                    if (response.status === 200) {
                        dispatch(loading(false));
                        setVerify(true);
                        if (message === "Recover password success") {
                            setUser({ username: "", pwd: "", cfpwd: "", email: "", otp: "" });
                            setVerify(false);
                        }

                        return toaster.success(message);
                    }
                })
                .catch((error) => {
                    const { message } = error.response.data;
                    toaster.error(message);
                    setCount(count + 1);
                    dispatch(loading(false));
                });
        } else {
            dispatch(loading(false));
        }
    };
    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        setUser({
            ...user,
            [name]: target.value,
        });
    };
    if (isLogin) {
        window.location.replace("/profile");
    }
    if (isBlocked) {
        return <Redirect to="/page/blocked" />;
    }
    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={true}
                toastOptions={{ style: { fontSize: 14 } }}
            />

            {/* background image */}
            <WrapLogin>
                <img src={`${bg}`} alt="bg-login" className="cover img-absolute" />
                <form onSubmit={onSubmit} method="post">
                    <p className="akaya">Dragon Heart</p>
                    {verify ? (
                        <input
                            type="text"
                            name="otp"
                            placeholder="OTP"
                            onChange={(value) => handleChange(value)}
                            autoComplete="off"
                        />
                    ) : (
                        <>
                            <div className="select">
                                {list_server.length > 0 ? (
                                    <select
                                        value={server?.slug}
                                        onChange={(e) => changeServer(e.target.value)}
                                    >
                                        {list_server.map((server, index) => {
                                            return (
                                                <option value={server?.slug} key={index}>
                                                    {server?.name}
                                                </option>
                                            );
                                        })}
                                    </select>
                                ) : null}
                            </div>
                            <input
                                type="text"
                                name="username"
                                placeholder="User name"
                                onChange={(value) => handleChange(value)}
                                autoComplete="off"
                            />
                            <input
                                type="text"
                                name="email"
                                placeholder="Your email address"
                                onChange={(value) => handleChange(value)}
                                autoComplete="off"
                            />
                            <input
                                type="password"
                                name="pwd"
                                placeholder="New password"
                                onChange={(value) => handleChange(value)}
                                autoComplete="off"
                            />
                            <input
                                type="password"
                                name="cfpwd"
                                placeholder="Confirm new password"
                                onChange={(value) => handleChange(value)}
                                autoComplete="off"
                            />
                        </>
                    )}
                    <button type="submit" ref={reRef}>
                        Submit
                    </button>
                    <WrapLoginRegister>
                        <Link to="/Login">Login</Link>
                        <Link to="/Register">Register</Link>
                    </WrapLoginRegister>
                    {/* captcha */}
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        size="invisible"
                        ref={reRef}
                    />
                </form>
            </WrapLogin>
        </>
    );
};
//login
const WrapLogin = styled.div`
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    transition: ease 350ms ease-in-out;

    form {
        width: 90%;
        max-width: 700px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 4% 2%;
        background-color: rgba(0, 0, 0, 0.15);
        align-items: center;
        border-radius: 20px;
    }

    input[type="text"],
    input[type="password"] {
        margin-top: 3%;
        padding: 16px;
        border: none;
        font-size: 16px;
        width: 100%;
        max-width: 484px;
        border-radius: 12px;
        text-indent: 1%;
        background-color: rgba(255, 255, 255, 0.4);
        color: #333;
        font-weight: 600;
    }
    input[type="text"]:focus,
    input[type="password"]:focus {
        outline: 0;
        border: 2px solid #6e6cf0;
    }
    button {
        padding: 2% 20%;
        border-radius: 8px;
        margin-top: 2%;
        font-size: 16px;
        background-color: rgba(255, 255, 255, 0.9);
    }
    p {
        font-size: 56px;
        margin-bottom: 2%;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 4px 2px #333;
    }
    @media (max-width: 768px) {
        p {
            font-size: 46px;
        }
        button {
            font-size: 14px;
        }
        input[type="text"],
        input[type="password"] {
            font-size: 14px;
        }
    }
    @media (max-width: 411px) {
        p {
            font-size: 36px;
        }
    }
`;

const WrapLoginRegister = styled.div`
    margin-top: 5%;
    width: 100%;
    display: flex;
    justify-content: space-around;

    a {
        color: #ffffff;
        opacity: 0.8;
    }
    a:hover {
        opacity: 1;
    }
`;
export default ForgotPassword;
