//image classes
// female
import mp0_1 from "../images/classes/mp0_1.png";
import mp1_1 from "../images/classes/mp1_1.png";
import mp2_1 from "../images/classes/mp2_1.png";
import mp3_1 from "../images/classes/mp3_1.png";
import mp4_1 from "../images/classes/mp4_1.png";
import mp5_1 from "../images/classes/mp5_1.png";
import mp6_1 from "../images/classes/mp6_1.png";
import mp7_1 from "../images/classes/mp7_1.png";
import mp8_1 from "../images/classes/mp8_1.png";
import mp9_1 from "../images/classes/mp9_1.png";
import mp10_1 from "../images/classes/mp10_1.png";
import mp11_1 from "../images/classes/mp11_1.png";
import mp12_1 from "../images/classes/mp12_1.png";
import mp13_1 from "../images/classes/mp13_1.png";
//male
import mp0_0 from "../images/classes/mp0_0.png";
import mp1_0 from "../images/classes/mp1_0.png";
import mp2_0 from "../images/classes/mp2_0.png";
import mp3_0 from "../images/classes/mp3_0.png";
import mp4_0 from "../images/classes/mp4_0.png";
import mp5_0 from "../images/classes/mp5_0.png";
import mp6_0 from "../images/classes/mp6_0.png";
import mp7_0 from "../images/classes/mp7_0.png";
import mp8_0 from "../images/classes/mp8_0.png";
import mp9_0 from "../images/classes/mp9_0.png";
import mp10_0 from "../images/classes/mp10_0.png";
import mp11_0 from "../images/classes/mp11_0.png";
import mp12_0 from "../images/classes/mp12_0.png";
import mp13_0 from "../images/classes/mp13_0.png";

const sections = [
   {
      section: "Shaolin",
      gender: "Male",
      image: mp7_0,
   },
   {
      section: "Shaolin",
      gender: "Female",
      image: mp7_1,
   },
   {
      section: "Pyromancer",
      gender: "Male",
      image: mp9_0,
   },
   {
      section: "Pyromancer",
      gender: "Female",
      image: mp9_1,
   },
   {
      section: "Beggars Alliance",
      gender: "Male",
      image: mp10_0,
   },
   {
      section: "Beggars Alliance",
      gender: "Female",
      image: mp10_1,
   },
   {
      section: "Taoist",
      gender: "Male",
      image: mp3_0,
   },
   {
      section: "Taoist",
      gender: "Female",
      image: mp3_1,
   },
   {
      section: "Lotus Order",
      gender: "Male",
      image: mp11_0,
   },
   {
      section: "Lotus Order",
      gender: "Female",
      image: mp11_1,
   },
   {
      section: "Voodoo",
      gender: "Male",
      image: mp2_0,
   },
   {
      section: "Voodoo",
      gender: "Female",
      image: mp2_1,
   },
   {
      section: "Royalty",
      gender: "Male",
      image: mp4_0,
   },
   {
      section: "Royalty",
      gender: "Female",
      image: mp4_1,
   },
   {
      section: "Assassin",
      gender: "Male",
      image: mp5_0,
   },
   {
      section: "Assassin",
      gender: "Female",
      image: mp5_1,
   },
   {
      section: "Minstrel",
      gender: "Male",
      image: mp1_0,
   },
   {
      section: "Minstrel",
      gender: "Female",
      image: mp1_1,
   },
   {
      section: "Heartless",
      gender: "Male",
      image: mp13_0,
   },
   {
      section: "Heartless",
      gender: "Female",
      image: mp13_1,
   },
   {
      section: "Muron",
      gender: "Male",
      image: mp8_0,
   },
   {
      section: "Muron",
      gender: "Female",
      image: mp8_1,
   },
   {
      section: "Tangmen",
      gender: "Male",
      image: mp0_0,
   },
   {
      section: "Tangmen",
      gender: "Female",
      image: mp0_1,
   },
   {
      section: "Magician",
      gender: "Male",
      image: mp6_0,
   },
   {
      section: "Magician",
      gender: "Female",
      image: mp6_1,
   },
   {
      section: "Peach Blossom",
      gender: "Male",
      image: mp12_0,
   },
   {
      section: "Peach Blossom",
      gender: "Female",
      image: mp12_1,
   },
   {
      section: "No Class",
      gender: "Male",
      image: mp12_0,
   },
   {
      section: "No Class",
      gender: "Female",
      image: mp12_1,
   },
];

export default sections;
