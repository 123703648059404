import { axiosInstance } from "./axios";
class DownloadService {
  async getListDownload() {
    const server    = localStorage.getItem("server_download");
    return axiosInstance.get(`/download/list-download?server=${server}`).then((response) => {
      return response.data;
    });
  }
}

export default new DownloadService();
