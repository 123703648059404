import React from "react";

const InputText = ({ placeholder, type, value, onChange, name }) => {
  return (
    <div style={{ maxWidth: 484 }} className="w-full top-16">
      <input type={type} placeholder={placeholder} name={name} onChange={onChange} value={value} autoComplete="off" />
    </div>
  );
};

export default InputText;
