import React from "react";
import * as IoIcons from "react-icons/io";
import { Link } from "react-router-dom";

//image
import logo from "../../images/dh/logo-256.png";
const BottomFooter = () => {
  return (
    <footer
      className="w-full max-w-8xl h-52 text-gray-400 bg-gray-800"
      style={{
        scrollSnapAlign: "start",
      }}
    >
      <div className="h-2 w-full bg-gradient-to-r from-brown to-yellow-100"></div>
      <div className="flex flex-wrap justify-center w-full gap-4 mt-4">
        <div className="flex  w-full space-y-2 justify-center items-center">
          <img src={logo} alt="logo" className="h-28 w-28" />
          <div className="ml-8 space-y-2">
            <a className="flex items-center" href="mailto:doheart2020@gmail.com">
              <IoIcons.IoMdMailOpen size="24" /> <span className="pl-4">doheart2020@gmail.com</span>
            </a>
            <a className="flex items-center" href="https://www.facebook.com/doheartss" target="_blank" rel="noopener noreferrer">
              <IoIcons.IoLogoFacebook size="24" />{" "}
              <span className="pl-4">
                Fanpage: <strong>Dragon Heart</strong>
              </span>
            </a>
          </div>
          <div className="ml-4 space-y-2">
            <Link to="/privacy-statement" className="flex">
              <span className="pl-10">Privacy Statement</span>
            </Link>
            <Link to="/terms-of-use" className="flex">
              <span className="pl-10">Terms of Use</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="border-t w-full border-gray-700 mt-4"></div>
      <div className="flex w-full flex-col">
        <p className="text-center mt-4">
          Copyright &copy; {new Date().getFullYear()} <strong>Dragon Heart Team</strong>. All rights reserved
        </p>
      </div>
    </footer>
  );
};

export default BottomFooter;
