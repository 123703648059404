import { axiosInstance } from "./axios";

class PostService {
  async getAllPosts() {
    return axiosInstance
      .get(`/posts/all-posts`)
      .then((response) => {
        const data = response.data.data;
        return data;
      })
      .catch((error) => {
        return [];
      });
  }
  async getDetailPost(id) {
    return axiosInstance
      .get(`/posts/filter-post/${id}`)
      .then((response) => {
        return response.data.data;
      })
      .catch((error) => {
        return {};
      });
  }
}
export default new PostService();
