import React from "react";
import bg1 from "../../images/bg1.jpg";
const Privacy = () => {
  return (
    <div className="min-h-screen max-w-8xl mx-auto">
      <div className="w-full h-full relative">
        <img src={bg1} className="absolute w-full h-full" alt="bg" style={{ zIndex: -10 }} />
        <h2 className="text-gray-900 text-base md:text-xl lg:text-3xl text-center mt-16 font-medium">PRIVACY STATEMENT</h2>
        <div className="prose prose-sm lg:prose-lg mt-16 mx-auto prose-indigo text-black max-w-7xl px-4">
          <p>
            Protecting your privacy is really important to us. With this in mind, we’re providing this Privacy Policy to explain our
            practices regarding the collection, use, and disclosure of information that we receive through our Services. This Privacy Policy
            does not apply to any third-party websites, services or applications, even if they are accessible through our Services. Also,
            please note that, unless we define a term in this Privacy Policy, all capitalized terms used in this Privacy Policy have the
            same meanings as in our Terms of Service. So, please make sure that you have read and understood our Terms of Service.
          </p>
          <p className="text-black text-base md:text-xl text-center mt-16 font-medium">
            COLLECTION AND USE OF INFORMATION WHAT WE COLLECT AND HOW WE USE IT
          </p>
          <p>
            Our primary goals in collecting information are to provide and improve our Services, to administer your use of the Services
            (including your Account, if you are an Account holder), to enable you to enjoy and easily navigate our Services, and to
            correspond with you regarding the Services and keep you updated on the Services. We collect your personal information in the
            following ways:
          </p>
          <p>
            <strong>INFORMATION YOU PROVIDE DIRECTLY TO US</strong>
            <br />
            <strong>Account Information.</strong> If you create an Account, we’ll collect information that we associate with your Account,
            including your username, email address, phone number, and date of birth.
            <br></br>
            <strong>Posting on the Site.</strong> We may offer publicly accessible Group Facebook where you may post content. You should be
            aware that, when you disclose information about yourself in these forums, all such content will be public.
            <br></br>
            <strong>Surveys.</strong> From time to time, we may contact you to participate in online surveys. If you do decide to
            participate, you may be asked to provide certain information that could identify you. We may use such information to improve our
            Sites and/or Services.
            <br></br>
            <strong>Transaction Information.</strong> When making purchases through the Services, our third-party payment processor will
            collect personal information such as your name, postal address, country, phone number, and credit card number. We do not store
            this information, and our payment processor will not share this information with us.
          </p>
          <p>
            <strong>INFORMATION WE AUTOMATICALLY COLLECT</strong>
            <br></br>
            <strong>Information Related to Use of the Services.</strong> Our servers automatically record certain information about how a
            person uses our Services (we refer to this information as “Log Data”), including both Account holders and non-Account holders
            (either, a “User”). Log Data may include information such as a User’s Internet Protocol (IP) address, browser type, operating
            system, the web page that a User was visiting before accessing our Services, the pages or features of our Services to which a
            User browsed and the time spent on those pages or features, search terms, the links on our Services that a User clicked on,
            in-game actions and behavior, and other statistics. We use Log Data to administer the Services. We analyze (and may engage third
            parties to analyze) Log Data to market and promote the Services and to improve, customize and enhance our Services by expanding
            their features and functionality and tailoring them to our Users’ needs and preferences. We may use a person’s IP address to
            generate aggregate, non-identifying information about how our Services are used.
            <br></br>
            <strong>Game Play Data.</strong> We automatically collect and store Users’ game play history and their progression through the
            games we offer. We use this data to administer and provide the services to users.
            <br></br>
            <strong>Information Collected Using Cookies and other Web Technologies.</strong> Like many website owners and operators, we use
            automated data collection tools such as Cookies to collect certain information.
          </p>
          <p className="text-black text-base md:text-xl text-center mt-16 font-medium">INFORMATION THAT WE SHARE WITH THIRD PARTIES</p>
          <p>
            We may share your information as described in this Privacy Policy; to comply with legal obligations; to protect and defend our
            rights and property) or with your permission.
          </p>
          <p>
            <strong>Information Shared with Our Services Providers.</strong> We may engage third-party services providers to work with us to
            administer and provide the Services and process payments. These third-party services providers have access to your personal
            information only for the purpose of performing services on our behalf. The types of service providers to whom we entrust
            personal information include service providers for: (i) provision of IT and related services; (ii) provision of information and
            services you have requested; (iii) customer service activities; (iv) payment processing; and (iv) in connection with the
            provision of the Site. [We have entered contracts with the service providers that prohibit them from using or sharing personal
            information except as necessary to perform the contracted services on our behalf or to comply with applicable legal
            requirements.]
            <br></br>
            <strong>Information Shared With Analytics Services.</strong> We use Google Analytics and other third-party analytics packages to
            collect, monitor and analyze the information we collect in order to increase the functionality and user-friendliness of the
            Services, to better tailor the Services to our visitors’ needs, and to understand and analyze how you are using the Services.
            YOUR CHOICE
          </p>
          <p className="text-black text-base md:text-xl text-center mt-16 font-medium">YOUR CHOICE</p>
          <p>
            We offer you choices regarding the collection, use and sharing of your personal information and we’ll respect the choices you
            make. Please note that if you decide not to provide us with the personal information that we request, you may not be able to
            access all of the features of the Services.
          </p>
          <p className="text-black text-base md:text-xl text-center mt-16 font-medium">
            RIGHTS OF ACCESS, RECTIFICATION, ERASURE, AND RESTRICTION
          </p>
          <p>
            You may inquire as to whether we are processing personal information about you, request access to personal information, and ask
            that we correct, amend or delete your personal information where it is inaccurate. If you are a User, you can access and modify
            your Account information through your account profile page. Where otherwise permitted by applicable law, you may send an e-mail
            to{" "}
            <strong>
              <a href="mailto:doheart2020@gmail.com">doheart2020@gmail.com</a>
            </strong>{" "}
            Please include your full name, email address associated with your Account, and a detailed description of your data request.
          </p>
          <p>
            Although we will make good faith efforts to provide you with access to your personal information, there may be circumstances in
            which we are unable to provide access, including but not limited to: where the information contains legal privilege, would
            compromise others’ privacy or other legitimate rights, where the burden or expense of providing access would be disproportionate
            to the risks to the Individual’s privacy in the case in question or where it is commercially proprietary. If we determine that
            access should be restricted in any particular instance, we will provide you with an explanation of why that determination has
            been made and a contact point for any further inquiries. To protect your privacy, Phoenix Labs will take commercially reasonable
            steps to verify your identity before granting access to or making any changes to your personal information.
          </p>
          <p className="text-black text-base md:text-xl text-center mt-16 font-medium">THE SECURITY OF YOUR INFORMATION</p>
          <p>
            We take reasonable administrative, physical and electronic measures designed to protect the information that we collect from or
            about you (including your personal information) from unauthorized access, use or disclosure. When you enter sensitive
            information on our forms, we encrypt this data using SSL or other technologies. Please be aware, however, that no method of
            transmitting information over the Internet or storing information is completely secure. Accordingly, we cannot guarantee the
            absolute security of any information.
          </p>
          <p className="text-black text-base md:text-xl text-center mt-16 font-medium">LINKS TO OTHER SITES</p>
          <p>
            Our Services may contain links to websites and services that are owned or operated by third parties (each, a “Third-party
            Service”). Any information that you provide on or to a Third-party Service or that is collected by a Third-party Service is
            provided directly to the owner or operator of the Third-party Service and is subject to the owner’s or operator’s privacy
            policy. We’re not responsible for the content, privacy or security practices and policies of any Third-party Service. To protect
            your information we recommend that you carefully review the privacy policies of all Third-party Services that you access.
          </p>
          <p className="text-black text-base md:text-xl text-center mt-16 font-medium">OUR POLICY TOWARD CHILDREN</p>
          <p>
            Our Services are not directed to children under 13 and we do not knowingly collect personal information from children under 13.
            If we learn that we have collected personal information of a child under 13 we will take steps to delete such information from
            our files as soon as possible.
          </p>
          <p className="pb-16">
            <strong>
              Questions? Please contact us at <a href="mailto:doheart2020@gmail.com">doheart2020@gmail.com</a> if you have any questions
              about our Privacy Policy.
            </strong>
          </p>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
