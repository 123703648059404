import axios from "axios";
let source;
if (source) {
   source.cancel();
}
source = axios.CancelToken.source();
const HOST = process.env.REACT_APP_HOST;
export const axiosInstance = axios.create({
   baseURL: HOST,
   timeout: 10000,
   cancelToken: source.token,
});
