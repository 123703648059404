import React from "react";

import styled from "styled-components";

// icon
import * as IoIcons from "react-icons/io";

import bg_sidebar_right from "../../images/bg-sidebar-right.png";
import btn_info from "../../images/buttons/button-info.svg";
import btn_info_a from "../../images/buttons/button-info-a.svg";
import btn_characters from "../../images/buttons/button-characters.svg";
import btn_characters_a from "../../images/buttons/button-characters-a.svg";
import btn_history from "../../images/buttons/button-history.svg";
import btn_history_a from "../../images/buttons/button-history-a.svg";
import btn_recharge from "../../images/buttons/recharge.png";

import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

const Sidebar = ({ show, onShow }) => {
  const dispatch = useDispatch();
  const pRoute = useSelector((state) => state.routeReducer.route);
  const changeRoute = (route) => {
    dispatch({
      type: "ROUTE",
      payload: route,
    });
  };
  return (
    <ProfileSidebar show={show}>
      <img src={bg_sidebar_right} alt="background-comm" className="w-full" />
      <CloseSidebar>
        {show ? (
          <IoIcons.IoMdArrowDroprightCircle size="24" cursor="pointer" color="#333" onClick={onShow} />
        ) : (
          <IoIcons.IoMdArrowDropleftCircle size="24" cursor="pointer" color="#333" onClick={onShow} />
        )}
      </CloseSidebar>
      <Links className="text-yellow-500 font-medium text-xs lg:text-base md:text-sm">
        <Link to="/profile/information" className="bg-cover" onClick={() => changeRoute("information")}>
          <img src={pRoute === "information" ? btn_info_a : btn_info} alt="information" />
        </Link>
        <Link to="/profile/characters" className="bg-cover" onClick={() => changeRoute("characters")}>
          <img src={pRoute === "characters" ? btn_characters_a : btn_characters} alt="characters" />
        </Link>
        <Link to="/profile/history-recharge" className="bg-cover" onClick={() => changeRoute("history-recharge")}>
          <img src={pRoute === "history-recharge" ? btn_history_a : btn_history} alt="history-recharge" />
        </Link>
        <Link to="/recharge" className="bg-cover recharge flex justify-center">
          <img src={btn_recharge} alt="recharge" />
        </Link>
      </Links>
    </ProfileSidebar>
  );
};

const ProfileSidebar = styled.div`
  position: absolute;
  right: ${({ show }) => (show ? "0" : "-10%")};
  width: 25%;
  height: auto;
  transition: 550ms;
  @media (min-width: 768px) {
    width: 14%;
  }
`;
const CloseSidebar = styled.div`
  position: absolute;
  z-index: 99;
  top: 10%;
`;
const Links = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 42%;
  right: 5.8%;
  width: 56%;
  a {
    margin-top: 5%;
    margin-left: 2%;
  }
  a:hover {
    opacity: 0.9;
  }
  .recharge {
    margin-top: 90%;
  }
`;
export default Sidebar;
