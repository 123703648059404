import React from "react";

const Modal = ({ promotions }) => {
  return (
    <div className="w-full h-auto">
      <div className="bg-gray-100 rounded-md" style={{ zIndex: 999 }}>
        {/* table  promotion */}
        <div className="w-full mx-auto">
          <div className="w-full flex flex-col">
            {/* header patch */}
            <div className="flex bg-button-green text-gray-200 py-2 text-sm md:text-base justify-around">
              <span className="w-1/2 flex-1 text-center">PACKAGE</span>
              <span className="w-1/12 text-center">PRICE (USD)</span>
              <span className="w-1/12 text-center ">SILVER</span>
              <span className="w-1/4 text-center ">TOKEN PER SILVER</span>
              <span className="w-1/2 flex-1 text-center ">PROMOTION</span>
              <span className="w-1/2 flex-1 text-center ">TOTAL</span>
            </div>

            {/* item top level */}
            {promotions.map((item, index) => {
              return (
                <div
                  className="flex justify-around bg-transparent font-medium border-b border-gray-300 py-2 text-green-900 text-sm md:text-base"
                  key={index}
                >
                  <span className="w-1/2 flex-1 text-center py-2">{item.title}</span>
                  <span className="w-1/12 text-center py-2 ">{item.price}</span>
                  <span className="w-1/12 text-center py-2 ">{item.silver}</span>
                  <span className="w-1/4 text-center py-2">{item.token}</span>
                  <span className="w-1/2 flex-1 text-center py-2">{"+" + item.promotion[0].value + "% silver"}</span>
                  <span className="w-1/2 flex-1 text-center py-2">{item.realSilver + " silver = " + item.total + " token"}</span>
                </div>
              );
            })}
            {/* end item top level */}
            {/* //note */}
            <p className="text-center py-4 mt-8 bg-button-green text-gray-200">
              Once transaction is success, the system will automatically add silvers to your account. To exchange Silver to Token, please go
              to map Bank in game
            </p>
          </div>
          {/* end list top level */}
        </div>
      </div>
    </div>
  );
};

export default Modal;
