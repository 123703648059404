import { axiosInstance } from "./axios";
class RankingService {
  async getListTopLevel() {
    const server     = localStorage.getItem("server");

    axiosInstance.defaults.headers.get["ChooseServer"] = server;
    return axiosInstance.get("/ranking/top-ranking").then((response) => {
      return response.data;
    });
  }
  async getListTopStrength() {
    return axiosInstance.get("/ranking/top-ranking").then((response) => {
      return response.data;
    });
  }
}

export default new RankingService();
