const initialState = {
  classes_active: 0,
  genre: 1,
};
export default function drawerReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case "CHANGE-CLASSES":
      return {
        ...state,
        classes_active: action.payload,
      };
    case "CHANGE-GENRE":
      return {
        ...state,
        genre: action.payload,
      };
    default:
      return state;
  }
}
