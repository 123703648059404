export const config = {
    new_bie_guide: "/posts/Guide-23",
    server_download: [
        {
            slug: "DH2",
            name: "Dragon Heart 2",
            active: 1,
            status: 0
        },
        {
            slug: "DH3",
            name: "Dragon Heart 3 & 4 & Special",
            active: 1,
            status: 1
        }
    ],
}