import Home from "./components/Home/Home";
import Page404 from "./components/Page404/Page404";
import Login from "./components/Account/Login";
import Register from "./components/Account/Register";
import Profile from "./components/Account/Profile";
import PageBlocked from "./components/Page404/PageBlocked";
import AllPost from "./components/Posts/AllPost";
import Download from "./components/Download/Download";
// import DownloadEffect from "./components/Download/DownloadEffect";
import ForgotPassword from "./components/Account/ForgotPassword";
import Terms from "./components/Terms/Terms";
import Privacy from "./components/Terms/Privacy";
import Ranking from "./components/Ranking/Ranking";
import Gallery from "./components/Gallery/Gallery";
import Recharge from "./components/Payments/Recharge";

const routes = [
  { path: "/", component: <Home />, redirect: "/home" },
  { path: "/home", component: <Home /> },
  { path: "/login", component: <Login /> },
  { path: "/register", component: <Register /> },

  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/download", component: <Download /> },
//   { path: "/noeffect-download", component: <DownloadEffect /> },
  { path: "/profile", component: <Profile />, redirect: "/profile/information" },
  { path: "/profile/:id", component: <Profile /> },
  { path: "/posts", component: <AllPost /> },
  { path: "/posts/:id", component: <AllPost /> },
  { path: "/page/blocked", component: <PageBlocked /> },
  { path: "/terms-of-use", component: <Terms /> },
  { path: "/privacy-statement", component: <Privacy /> },
  { path: "/ranking", component: <Ranking /> },
  { path: "/gallery", component: <Gallery /> },
  { path: "/recharge", component: <Recharge /> },
  { path: "/*", component: <Page404 /> },
];
export default routes;
