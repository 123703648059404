export const newsData = [
  {
    id: 1,
    type: "All",
  },
  {
    id: 2,
    type: "News",
  },
  {
    id: 3,
    type: "Guide",
  },
  {
    id: 4,
    type: "Notify",
  },
];

