import React from "react";

const PageBlocked = () => {
    return (
        <div className="w-full min-h-screen flex justify-center items-center">
            <h2>Please visit again after a few minutes</h2>
        </div>
    );
};

export default PageBlocked;
