import React from "react";
import styled from "styled-components";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectCoverflow, Autoplay, Pagination, Navigation } from "swiper";

import { SlideData } from "./News/SlideData";

import btnprev from "../../images/prev_btn.png";
import btnnext from "../../images/next_btn.png";
import title_slide from "../../images/home/page4.png";

SwiperCore.use([EffectCoverflow, Autoplay, Pagination, Navigation]);

const SlideShow = () => {
  return (
    <SlideShowContainer>
      <div className="my-12 w-2/4 mx-auto">
        <img src={title_slide} alt="title-slide" />
      </div>
      <div className="w-10/12 mx-auto">
        <Swiper
          pagination={{
            el: ".swiper-pagination",
            type: "bullets",
          }}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          loop={true}
          speed={2000}
          autoplay={{
            delay: 4000,
            disableOnInteraction: false,
          }}
          effect="coverflow"
          slidesPerView={1.5}
          centeredSlides={true}
          spaceBetween={20}
          coverflowEffect={{
            rotate: 0,
            stretch: 10,
            depth: 100,
            modifier: 2,
            slideShadows: true,
          }}
        >
          {SlideData.map((slide, index) => {
            return (
              <SwiperSlide key={`slide-${index}`}>
                <img src={slide.path} alt={`img-slide-${index}`} className="contain" />
              </SwiperSlide>
            );
          })}

          <BtnPrev className="swiper-button-prev">
            <img src={btnprev} alt="btn_prev" className="contain" />
          </BtnPrev>
          <BtnNext className="swiper-button-next">
            <img src={btnnext} alt="btn_next" className="contain" />
          </BtnNext>
          <SlidePagination className="swiper-pagination">
            <span className="swiper-pagination-bullet"></span>
          </SlidePagination>
        </Swiper>
      </div>
    </SlideShowContainer>
  );
};
const SlideShowContainer = styled.section`
  scroll-snap-align: start;
  width: 100%;
  height: 100vh;
  position: relative;
  padding-top: 3vh;

  p {
    text-align: center;
    font-weight: bold;
    font-size: 2.5vw;
    margin-bottom: 5%;
  }
`;
const BtnPrev = styled.div`
  width: 3.5%;
  height: auto;
  position: absolute;
  top: 42%;
  left: 8%;
  margin: 0 auto;
  z-index: 99;
  cursor: pointer;
`;
const BtnNext = styled.div`
  width: 3.5%;
  height: auto;
  position: absolute;
  top: 42%;
  right: 8%;
  margin: 0 auto;
  z-index: 99;
  cursor: pointer;
`;
const SlidePagination = styled.div`
  display: flex;
  flex-direction: row;
  height: auto;
  width: 40%;
  align-items: center;
  margin: 0 auto;
  z-index: 999;
  margin-top: 2%;
`;
export default SlideShow;
