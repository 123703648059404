import React, { useRef, useState } from "react";
import styled from "styled-components";

// lodash
//import _ from "lodash";

// router
import { Link, Redirect } from "react-router-dom";
//toast
import toaster, { Toaster } from "react-hot-toast";

//images
import bg from "../../images/bg-login.jpg";

//captcha google
import ReCAPTCHA from "react-google-recaptcha";

//redux
import { useDispatch, useSelector } from "react-redux";
import { loading, userLogin } from "../../redux/action";

//api
import AuthService from "../../services/auth.service";
import setAuthToken from "../../services/authToken";
import { useZustandStore } from "../../zstore";

const Login = () => {
    const isLogin = useSelector((state) => state.userReducer.isLogin);
    const isBlocked = useSelector((state) => state.userReducer.blocked);

    const dispatch = useDispatch();
    const reRef = useRef();
    const [user, setUser] = useState({ username: "", pwd: "" });
    const [count, setCount] = useState(1);

    //zustand
    const { list_server, server, changeServer } = useZustandStore();

    const onSubmit = async (e) => {
        e.preventDefault();
        //loading
        dispatch(loading(true));
        const reCaptcha = await reRef.current.executeAsync();
        if (count < 5) {
            reRef.current.reset();
        }
        if (count >= 5) {
            const block = Date.now() / 1000 + 5 * 60;
            localStorage.setItem("_blocked", block.toString());
        }
        await AuthService.login(user, reCaptcha)
            .then((response) => {
                const { token, code } = response.data;
                if (code === 0) {
                    if (token) {
                        dispatch(loading(false));
                        dispatch(userLogin(true));
                        setAuthToken(token);
                        sessionStorage.setItem("_token", token);
                    } else {
                        toaster.error(
                            "Something went wrong , please try again after a few minutes"
                        );
                        setCount(count + 1);
                        dispatch(loading(false));
                    }
                }
                if (code === -101) {
                    setCount(count + 1);
                    toaster.error("Username or Password invalid");
                    dispatch(loading(false));
                }
                if (code === -103) {
                    setCount(count + 1);
                    toaster.error("Network error , please try again after a few minutes");
                    dispatch(loading(false));
                }
                if (code === -102) {
                    setCount(count + 1);
                }
            })
            .catch((error) => {
                setCount(count + 1);
                dispatch(loading(false));
                return toaster.error("Something went wrong , please try again after a few minutes");
            });
    };
    const handleChange = (e) => {
        const target = e.target;
        const name = target.name;
        setUser({
            ...user,
            [name]: target.value,
        });
    };
    if (isLogin) {
        window.location.replace("/profile");
    }
    if (isBlocked) {
        return <Redirect to="/page/blocked" />;
    }
    return (
        <>
            <Toaster
                position="top-center"
                reverseOrder={true}
                toastOptions={{ style: { fontSize: 14 } }}
            />

            {/* background image */}
            <WrapLogin>
                <img src={`${bg}`} alt="bg-login" className="cover img-absolute" />
                <form onSubmit={onSubmit} method="post">
                    <p className="akaya text-red-500">Dragon Heart</p>
                    <div className="select">
                        {list_server.length > 0 ? (
                            <select
                                value={server?.slug}
                                onChange={(e) => changeServer(e.target.value)}
                            >
                                {list_server.map((server, index) => {
                                    return (
                                        <option value={server?.slug} key={index}>
                                            {server?.name}
                                        </option>
                                    );
                                })}
                            </select>
                        ) : null}
                    </div>
                    <input
                        type="text"
                        name="username"
                        placeholder="User name"
                        onChange={(value) => handleChange(value)}
                        autoComplete="off"
                    />
                    <input
                        type="password"
                        name="pwd"
                        placeholder="Password"
                        onChange={(value) => handleChange(value)}
                        autoComplete="off"
                    />
                    <button type="submit" ref={reRef}>
                        Login
                    </button>
                    <WrapLoginRegister>
                        <Link to="/register">Register</Link>
                        <Link to="/forgot-password">Forgot password ?</Link>
                    </WrapLoginRegister>
                    {/* captcha */}
                    <ReCAPTCHA
                        sitekey={process.env.REACT_APP_SITE_KEY}
                        size="invisible"
                        ref={reRef}
                    />
                </form>
            </WrapLogin>
        </>
    );
};
//login
const WrapLogin = styled.div`
    width: 100%;
    height: 100vh;
    max-width: 1920px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    transition: ease 350ms ease-in-out;

    form {
        width: 90%;
        max-width: 700px;
        height: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 4% 2%;
        background-color: rgba(0, 0, 0, 0.15);
        align-items: center;
        border-radius: 20px;
    }

    input[type="text"],
    input[type="password"] {
        margin-top: 3%;
        padding: 16px;
        border: none;
        font-size: 16px;
        width: 100%;
        max-width: 484px;
        border-radius: 12px;
        text-indent: 1%;
        background-color: rgba(255, 255, 255, 0.4);
        color: #333;
        font-weight: 600;
    }
    input[type="text"]:focus,
    input[type="password"]:focus {
        outline: 0;
        border: 2px solid #6e6cf0;
    }
    button {
        padding: 2% 20%;
        border-radius: 8px;
        margin-top: 2%;
        font-size: 16px;
        background-color: rgba(255, 255, 255, 0.9);
    }
    p {
        font-size: 56px;
        margin-bottom: 2%;
        font-weight: bold;
        color: #ffffff;
        text-shadow: 4px 2px #333;
    }
    @media (max-width: 768px) {
        p {
            font-size: 46px;
        }
        button {
            font-size: 14px;
        }
        input[type="text"],
        input[type="password"] {
            font-size: 14px;
        }
    }
    @media (max-width: 411px) {
        p {
            font-size: 36px;
        }
    }
`;

const WrapLoginRegister = styled.div`
    margin-top: 5%;
    width: 100%;
    display: flex;
    justify-content: space-around;

    a {
        color: #ffffff;
        opacity: 0.8;
    }
    a:hover {
        opacity: 1;
    }
`;
export default Login;
