import React, { useEffect } from "react";
//router
import { BrowserRouter as Router, Redirect, Route, Switch } from "react-router-dom";

//routes
import routes from "./routes";

//loading
import Loading from "./components/Loading/Loading";

import { useSelector, useDispatch } from "react-redux";
import { userProfile, userLogin, userBlocked, loadSmallPosts } from "./redux/action";

//decode
import encoder from "./helpers/encoder";

//zustand
import { useZustandStore } from "./zstore";

//services
import PostService from "./services/post.service";
import PaymentService from "./services/payment.service";
import UserService from "./services/user.service";

//auth axios
import setAuthToken from "./services/authToken";
import Navbar from "./components/Nav/Navbar";
import ScrollToTop from "./components/Scroll/ScrollToTop";

import MessengerCustomerChat from "react-messenger-customer-chat";

// import io from "socket.io-client";
// const socket = io(process.env.REACT_APP_HOST, { transports: ["websocket"] });

function App() {
	const mainRef = React.useRef(true);

	//zustand
	const { getListConfigServerActive, getListDownload, getListRanking } = useZustandStore();

	const dispatch = useDispatch();
	const token = sessionStorage.getItem("_token");
	// const key = localStorage.getItem("_keyD");
	const blocked = localStorage.getItem("_blocked");

	const isLoading = useSelector((state) => state.loadingReducer.loading);
	if (token) {
		const decoded = encoder.JWTDecrypt(token);
		const aesDecrypted = encoder.AESDecrypt(decoded.user);
		const user = JSON.parse(aesDecrypted);
        setAuthToken(token);

		//profile
		dispatch(userProfile(user));

		//characters
		dispatch({
			type: "USER-CHARACTER",
			payload: user.characters,
		});

		// if expire then logout , require login
		const currentTime = Date.now() / 1000;
		const expTime = parseInt(decoded.exp);
		if (currentTime > expTime) {
			dispatch(userLogin(false));
			sessionStorage.removeItem("_token");
		}
		// if none expire then profile
		else {
			dispatch(userLogin(true));
		}
	}
	if (blocked) {
		const currentTime = Date.now() / 1000;
		const blockedTime = parseInt(blocked);
		if (currentTime > blockedTime) {
			dispatch(userBlocked(false));
			localStorage.removeItem("_blocked");
		} else {
			dispatch(userBlocked(true));
		}
	}
	// const generateKey = async () => {
	//    if (!key) {
	//       const genKey = await AuthService.genarateKey();
	//       localStorage.setItem("_keyD", genKey.data);
	//    }
	// };
	const loadPosts = React.useCallback(async () => {
		let posts;
		try {
			posts = await PostService.getAllPosts();
		} catch (error) {
			posts = [];
		}
		dispatch({
			type: "INIT-POST",
			payload: posts,
		});
		dispatch({
			type: "INIT-SLIDE",
			payload: posts,
		});
		dispatch({
			type: "POST-ID",
			payload: posts[0]?.id,
		});
		dispatch({
			type: "APOSTS",
			payload: posts,
		});
		dispatch(loadSmallPosts("All"));
	}, [dispatch]);

	//pay amount list
	const getPayamoutList = React.useCallback(async () => {
		let payamount;
		try {
			payamount = await PaymentService.getPayamountList();
		} catch (error) {
			payamount = [];
		}
		dispatch({
			type: "PAY-AMOUNT",
			payload: payamount,
		});
	}, [dispatch]);

	//get point
	const getPoint = React.useCallback(() => {
		UserService.getPoint()
			.then((response) => {
				dispatch({
					type: "GET-POINT",
					payload: response,
				});
			})
			.catch(() => {
				dispatch({
					type: "GET-POINT",
					payload: "",
				});
			});
	}, [dispatch]);

	//reload page
	// const reloadPage = () => {
	//   setTimeout(() => {
	//     sessionStorage.removeItem("_token");
	//   }, [15 * 60 * 1000]);
	// };

	//socket
	// const initSocket = () => {
	//   dispatch({
	//     type: "SOCKET",
	//     payload: socket,
	//   });
	// };
	// const receiveMessage = () => {
	//   socket.on("message", function (msg) {
	//     const message = JSON.parse(msg);
	//     dispatch({
	//       type: "CHAT",
	//       payload: message,
	//     });
	//   });
	// };

	useEffect(() => {
		if (mainRef.current) {
			mainRef.current = false;
			loadPosts();
			getListDownload();
			getListRanking();
			getPayamoutList();
			getPoint();
			getListConfigServerActive();
		}
		return () => {
			mainRef.current = false;
		};
	}, [getListConfigServerActive, getListDownload, getListRanking, getPayamoutList, getPoint, loadPosts]);
	if (mainRef.current) {
		return <></>;
	}
	return (
		<Router>
			<Navbar />
			<div className="container">
				<MessengerCustomerChat pageId={process.env.REACT_APP_FACEBOOK_PAGE_ID} appId={process.env.REACT_APP_FACEBOOK_APP_ID} />
				<ScrollToTop />
				{isLoading && <Loading />}
				<Switch>
					{routes.map((route, index) => {
						return (
							<Route path={route.path} exact key={index}>
								{route.redirect ? <Redirect to={route.redirect} /> : route.component}
							</Route>
						);
					})}
				</Switch>
			</div>
		</Router>
	);
}

export default App;
