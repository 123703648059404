import React from "react";
import styled from "styled-components";

import genre_female from "../../images/classes/genre_female.png";
import genre_male from "../../images/classes/genre_male.png";
import hjdi from "../../images/hjdi.png";
import title_classes from "../../images/home/page3.png";

import { ClassesNameData } from "./Classes/ClassesData";

// redux
import { useDispatch, useSelector } from "react-redux";
import { changeClassesActive, changeGenre } from "../../redux/action";

const Classes = () => {
  const dispatch = useDispatch();
  const classes_active = useSelector((state) => state.classesReducer.classes_active);
  const genre = useSelector((state) => state.classesReducer.genre);
  const onActiveClasses = (index) => {
    dispatch(changeClassesActive(index));
  };
  const onChangeGenre = (genre) => {
    dispatch(changeGenre(genre));
  };
  return (
    <ClassesLayout>
      <div className="mx-auto w-2/4 mt-14">
        <img src={title_classes} alt="title classes" />
      </div>
      <WrapMedia>
        <ClassesIconName>
          {ClassesNameData.map((item, index) => {
            return (
              <div
                key={index}
                className={`classes_name ${classes_active === item.id ? "classes_active" : ""}`}
                onClick={() => onActiveClasses(item.id)}
              >
                <img src={`${item.icon_name}`} alt="icon-name" className="contain" />
              </div>
            );
          })}
        </ClassesIconName>
        <ClassesGenre>
          {genre === 1 && <img src={`${genre_female}`} alt="female" className="contain" />}
          {genre === 0 && <img src={`${genre_male}`} alt="male" className="contain" />}

          <div className="genre">
            <div className="male" onClick={() => onChangeGenre(0)}></div>
            <div className="female" onClick={() => onChangeGenre(1)}></div>
          </div>
        </ClassesGenre>

        <ClassesName id={classes_active}>
          <img
            src={ClassesNameData[classes_active].image_name}
            alt={`classes name - ${ClassesNameData[classes_active].image_name}`}
            className="contain"
          />
        </ClassesName>
        <HJDI>
          <img src={hjdi} alt="hjdi" className="cover" />
        </HJDI>
        <ClassesImage>
          <img
            src={`${genre === 1 ? ClassesNameData[classes_active].image_path_female : ClassesNameData[classes_active].image_path_male}`}
            alt="shaolin"
            className="cover"
          />
        </ClassesImage>
        {/* <ClassesWeapon id_wp={`${classes_active}`}>
               <img src={ClassesNameData[classes_active].weapon} alt="image-name" className="contain" />
            </ClassesWeapon> */}
        <ClassesIntro>
          <img src={ClassesNameData[classes_active].intro} alt="intro" className="contain" />
        </ClassesIntro>
      </WrapMedia>
    </ClassesLayout>
  );
};

const ClassesLayout = styled.section`
  scroll-snap-align: start;
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  align-items: flex-start;
  margin: 2% auto;
  flex-direction: column;

  .title-classes {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 2.5vw;
  }
`;
const HJDI = styled.div`
  width: 60%;
  height: auto;
  position: absolute;
  top: 20%;
  right: 10%;
`;
const ClassesIconName = styled.div`
  display: flex;
  width: 48%;
  height: auto;
  flex-wrap: wrap;
  position: absolute;
  top: 11%;
  right: 10%;
  margin: 0 auto;
  z-index: 99;
  .classes_name {
    display: flex;
    flex-direction: row;
    height: auto;
    opacity: 0.6;
    width: 100%;
    flex: 1;
    cursor: pointer;
  }
  .classes_name:hover {
    opacity: 1;
  }
  .classes_active {
    opacity: 1;
  }
`;
const ClassesName = styled.div`
  width: ${({ id }) => (id >= "10" && "14%") || (id >= "6" && id <= "9" && "16%") || "15%"};
  height: auto;
  display: flex;
  justify-content: center;
  font-size: 0.6vw;
  position: absolute;
  cursor: pointer;
  transition: 350ms;
  top: ${({ id }) => (id >= "1" && id <= "9" ? "15%" : "12%")};
  left: 11%;
  z-index: 99;
`;
const WrapMedia = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const ClassesImage = styled.div`
  display: flex;
  width: 30%;
  height: auto;
  position: relative;
  margin-right: 30%;
  pointer-events: auto;
`;
const ClassesGenre = styled.div`
  width: 5%;
  height: auto;
  display: flex;
  justify-content: center;
  font-size: 0.6vw;
  margin-top: 1%;
  position: absolute;
  cursor: pointer;
  transition: 350ms;
  top: 22%;
  left: 45%;
  z-index: 99;

  .genre {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    border-radius: 50%;
    display: flex;
    transition: 350ms;
  }
  .female {
    height: 100%;
    width: 50%;
    transition: 350ms;
  }
  .male {
    height: 100%;
    width: 50%;
    transition: 350ms;
  }
`;

// const ClassesWeapon = styled.div`
//    width: 7.5%;
//    height: auto;
//    display: flex;
//    justify-content: center;
//    font-size: 0.6vw;
//    margin-top: 1%;
//    position: absolute;
//    cursor: pointer;
//    transition: 350ms;
//    left: 45%;
//    margin-top: 5%;
//    z-index: 1;
// `;

const ClassesIntro = styled.div`
  width: 45%;
  height: auto;
  display: flex;
  justify-content: center;
  font-size: 0.6vw;
  margin-top: 1%;
  position: absolute;
  bottom: 15%;
  right: 15%;
  z-index: 1;
`;
export default Classes;
