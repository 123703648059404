import React, { useState } from "react";
import ProfileSidebar from "../Sidebar/ProfileSidebar";

import bg from "../../images/bg1-4.jpg";

import styled from "styled-components";

import { Switch, Route, Redirect } from "react-router-dom";

//route
import route from "./ProfileRoute";

//redux
import { useSelector } from "react-redux";

const Profile = () => {
  const [showSideBar, setShowSideBar] = useState(true);

  // token
  const isLogin = useSelector((state) => state.userReducer.isLogin);
  const pRoute = useSelector((state) => state.routeReducer.route);

  if (!isLogin) {
    return <Redirect to="/login" />;
  }
  return (
    <>
      <ProfileContainer>
        {/* <RoutesName>
               <img src={info} alt="information" />
            </RoutesName> */}
        <img src={bg} alt="background-comm" className="cover img-absolute" />
        <ProfileSidebar
          show={showSideBar}
          onShow={() => {
            setShowSideBar(!showSideBar);
          }}
        />
        <ProfileContent>
          <Switch>
            {/* router profile route */}
            {route.map((item, index) => {
              return (
                <Route path={item.location} exact key={index}>
                  {pRoute === item.id ? item.component : <Redirect to={pRoute} />}
                </Route>
              );
            })}
            <Route path={`/profile/*`} exact>
              <Redirect to="/not-found" />
            </Route>
          </Switch>
        </ProfileContent>
      </ProfileContainer>
    </>
  );
};
const ProfileContainer = styled.div`
  width: 100%;
  max-width: 1920px;
  height: 100vh;
  position: relative;
  margin: 0 auto;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ProfileContent = styled.div`
  width: 100%;
  height: auto;
  max-width: 1920px;
  height: 100vh;
  margin: 0 auto;
`;
export default Profile;
