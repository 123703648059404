import React, { useEffect, useState, useRef, Fragment } from "react";
import styled from "styled-components";
import toaster, { Toaster } from "react-hot-toast";

import num1 from "../../../images/num1.png";
import num_active from "../../../images/num-active.png";

import { useSelector, useDispatch } from "react-redux";
import { loading } from "../../../redux/action";

import _ from "lodash";

//sections
import sections from "../../../constant/section";

import UserService from "../../../services/user.service";
import PetService from "../../../services/pet.service";

//captcha google
import ReCAPTCHA from "react-google-recaptcha";

import { getListPetByCharacter } from "../../../redux/action";
import ModalPet from "../Pet/ModalPet";
const Characters = () => {
  const dispatch = useDispatch();
  //ref captcha
  const reRef = useRef();
  let dialogRef = useRef(null);

  const characters = useSelector((state) => state.userReducer.characters);
  const pets = useSelector((state) => state.petReducer.pets);

  //state
  const [character, setCharacter] = useState();
  const [isOpen, setIsOpen] = useState(false);

  const renderCharacter = (item) => {
    let char = {};

    if (_.isEmpty(item) || _.isNil(item)) {
      setCharacter(char);
      return char;
    }
    sections.forEach((section) => {
      if (item.section === section.section && item.gender === section.gender) {
        item.image = section.image;
        char = item;
      }
    });
    setCharacter(char);
    return char;
  };

  //action reset time , fix jam map , fix minus exp
  const fixAction = async (type) => {
    dispatch(loading(true));
    const reCaptcha = await reRef.current.executeAsync();
    await UserService.serviceFixAction(character.id, type, reCaptcha)
      .then((response) => {
        const { message } = response.data;
        dispatch(loading(false));
        return toaster.success(message);
      })
      .catch((error) => {
        const { message } = error.response.data;
        dispatch(loading(false));
        return toaster.error(message);
      });
  };

  useEffect(() => {
    const char = renderCharacter(characters[0]);
    setCharacter(char);
    return () => {
      // Clean up the subscription
      setCharacter(char);
    };
  }, [characters]);

  // get list pet by character
  const getListPet = () => {
    dispatch({
      type: "LIST-PET",
      payload: [],
    });
    getListPetByCharacter(character.id, dispatch);
    setIsOpen(true);
  };

  const upgradePet = async (id, aid) => {
    dispatch(loading(true));
    await PetService.upgradePetBoss(id, aid)
      .then((response) => {
        const { message } = response.data;
        dispatch(loading(false));
        return toaster.success(message);
      })
      .catch((error) => {
        const { message } = error.response.data;
        dispatch(loading(false));
        return toaster.error(message);
      });
  };

  if (_.isNil(character) || _.isEmpty(character)) {
    return <></>;
  }
  return (
    <>
      <Toaster
        position="top-center"
        reverseOrder={true}
        toastOptions={{ style: { fontSize: 14 } }}
      />
      <WrapCharacters>
        <ImageCharacter>
          <img src={character.image} alt={character.image} />
        </ImageCharacter>

        {/* buttons change class */}
        {characters[0]?.id && (
          <div
            style={{ top: "30%", left: "28%" }}
            className="w-16 h-16 absolute flex items-center justify-center cursor-pointer"
            onClick={() => renderCharacter(characters[0])}
          >
            <span
              className={`akaya z-10 text-xs lg:text-base md:text-sm font-medium ${
                characters[0]?.id === character.id ? "text-gray-50" : "text-green-900"
              } `}
            >
              One
            </span>
            <img
              src={characters[0]?.id === character.id ? num_active : num1}
              alt="bt1"
              className="absolute"
            />
          </div>
        )}
        {characters[1]?.id && (
          <div
            style={{ top: "38%", left: "29%" }}
            className="w-16 h-16 absolute flex items-center justify-center cursor-pointer"
            onClick={() => renderCharacter(characters[1])}
          >
            <span
              className={`akaya z-10 text-xs lg:text-base md:text-sm font-medium ${
                characters[1]?.id === character.id ? "text-gray-50" : "text-green-900"
              } `}
            >
              Two
            </span>
            <img
              src={characters[1]?.id === character.id ? num_active : num1}
              alt="bt1"
              className="absolute"
            />
          </div>
        )}
        {characters[2]?.id && (
          <div
            style={{ top: "46%", left: "31%" }}
            className="w-16 h-16 absolute flex items-center justify-center cursor-pointer"
            onClick={() => renderCharacter(characters[2])}
          >
            <span
              className={`akaya z-10 text-xs lg:text-base md:text-sm font-medium ${
                characters[2]?.id === character.id ? "text-gray-50" : "text-green-900"
              } `}
            >
              Three
            </span>
            <img
              src={characters[2]?.id === character.id ? num_active : num1}
              alt="bt1"
              className="absolute"
            />
          </div>
        )}
        {/* end buttons change class */}

        {/* character name */}
        <div
          style={{ right: "24%", top: "15%" }}
          className="absolute flex flex-row items-center bg-gradient-to-r from-brown via-yellow-50 space-x-4 w-96"
        >
          <div className="p-1 rounded-sm py-2 px-2">
            <span className="font-semibold text-text-brown akaya text-base">Character name</span>
          </div>
          <span className="text-3xl font-semibold akaya text-green-900">{character.name}</span>
        </div>
        {/* end character name */}

        {/* character class */}
        <div
          style={{ right: "21%", top: "20%" }}
          className="absolute flex flex-row items-center bg-gradient-to-r from-brown via-yellow-50 space-x-4 w-96"
        >
          <div className="p-1 rounded-sm py-2 px-2">
            <span className="font-semibold text-text-brown akaya text-base">Class</span>
          </div>
          <span className="text-2xl font-semibold akaya text-green-900">{character.section}</span>
        </div>
        {/* end character class */}

        {/* character level */}
        <div
          style={{ right: "19%", top: "25%" }}
          className="absolute flex flex-row items-center bg-gradient-to-r from-brown via-yellow-50 space-x-4 w-96"
        >
          <div className="p-1 rounded-sm py-2 px-2">
            <span className="font-semibold text-text-brown akaya text-base">Level</span>
          </div>
          <span className="text-2xl font-semibold akaya text-green-900">{character.level}</span>
        </div>
        {/* end character level */}

        {/* character exp points */}
        <div
          style={{ right: "17%", top: "30%" }}
          className="absolute flex flex-row items-center bg-gradient-to-r from-brown via-yellow-50 space-x-4 w-96"
        >
          <div className="p-1 rounded-sm py-2 px-2">
            <span className="font-semibold text-text-brown akaya text-base">Exp points</span>
          </div>
          <span className="text-2xl font-semibold akaya text-green-900">
            {character.exp.toLocaleString("en-US")}
          </span>
        </div>
        {/* end character exp points */}

        {/* button reset time */}
        <button
          style={{ left: "28%", bottom: "36%" }}
          className="absolute w-40 h-16 flex items-center justify-center rounded-sm btn_reset-time"
          ref={reRef}
          onClick={() => fixAction("RESET-TIME")}
        ></button>
        {/* end button reset time */}

        {/* button fix jam map  */}
        <button
          style={{ bottom: "30%", left: "30%" }}
          className="absolute w-40 h-16 flex items-center justify-center rounded-sm btn_fix-jam-map"
          ref={reRef}
          onClick={() => fixAction("FIX-JAM-MAP")}
        >
        </button>
        {/* end button fix jam map */}

        {/* button fix minus exp */}
        <button
          style={{ left: "32%", bottom: "24%" }}
          className="absolute w-40 h-16 flex items-center justify-center rounded-sm btn_fix-minus-exp"
          ref={reRef}
          onClick={() => fixAction("FIX-MINUS-EXP")}
        ></button>
        {/* end button fix minus exp */}

        {/* button unlock character */}
        <button
          style={{ left: "30%", bottom: "18%" }}
          className="absolute w-40 h-16 flex items-center justify-center rounded-sm btn_unlock"
          ref={reRef}
          onClick={() => fixAction("UNLOCK")}
        ></button>
        {/* end button unlock character */}

        {/* open modal list pet */}
        <button
          style={{ left: "28%", bottom: "12%" }}
          className="w-40 h-16 absolute flex items-center justify-center cursor-pointer btn_upgrade-pet"
          onClick={() => getListPet()}
        ></button>

        {/* captcha */}
        <ReCAPTCHA
          sitekey={process.env.REACT_APP_SITE_KEY}
          size="invisible"
          ref={reRef}
          type="image"
        />
      </WrapCharacters>

      {/* modal list pet */}
      <ModalPet
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        listPet={pets}
        dialogRef={dialogRef}
        upGradePet={upgradePet}
      />
    </>
  );
};
const WrapCharacters = styled.div`
  display: flex;
  height: 100%;
  margin: 0 auto;
  flex-wrap: wrap;
`;
const ImageCharacter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-height: 75%;
  position: relative;
  margin-left: 2%;
  margin-top: 5%;
  img {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  pointer-events: none;
`;
export default Characters;
