import React, { useState, useEffect } from "react";

import _ from "lodash";
import PaymentService from "../../../services/payment.service";

import { formatDateTime } from "../../../helpers/helpers";
const HistoryRecharge = () => {
  const [payments, setPayments] = useState([]);
  const [offset, setOffset] = useState(1);
  const [totalPage, setTotalPage] = useState();
  const [silvers, setSilvers] = useState();
  const [usd, setUsd] = useState();

  useEffect(() => {
    const fetchData = async () => {
      const { payments, totalPage, silvers, usd } = await PaymentService.historyPayment(7, offset);
      setPayments(payments);
      setTotalPage(totalPage);
      setSilvers(silvers);
      setUsd(usd);
    };
    fetchData();
  }, [offset]);
  if (_.isNil(payments) || _.isNil(totalPage) || _.isNil(silvers) || _.isNil(usd)) {
    return <></>;
  }
  return (
    <div className="w-full mx-auto max-w-8xl">
      <div className="max-w-7xl mt-32 m-auto mb-2 md:mb-8">
        <div className="w-full px-8 py-8 mt-2 lg:mt-4 flex flex-col">
          {/* header patch */}
          <div className="flex bg-button-green text-gray-200 py-2 text-sm md:text-base lg:text-lg justify-around">
            <span className="w-1/12 text-center">#</span>
            <span className=" w-1/2 flex-1 text-center">Method</span>
            <span className="w-1/2 flex-1 text-center ">Amount</span>
            <span className="w-1/2 flex-1 text-center ">Time</span>
          </div>

          {/* item top level */}
          {payments.map((item, index) => {
            return (
              <div
                className="flex justify-around bg-transparent font-medium border-b border-gray-300 py-2 text-green-900 text-sm md:text-base lg:text-lg"
                key={index}
              >
                <span className="w-1/12 text-center py-2">{index + 1}</span>
                <span className="w-1/2 flex-1 text-center py-2 ">{item.payment_method}</span>
                <span className="w-1/2 flex-1 text-center py-2 ">
                  {item.cost} {item.payment_method === "Mobiamo" ? "Silver" : "$"}
                </span>
                <span className="w-1/2 flex-1 text-center py-2 truncate">{formatDateTime(item.time)}</span>
              </div>
            );
          })}
          {/* end item top level */}

          {/* total */}
          <span className="font-medium text-md text-center py-4 border border-gray-300 mx-auto px-4 text-green-900 my-2 rounded-md">
            Total: {`${usd + "$"} + ${silvers + " Silvers"}`}
          </span>
          {/* end total */}

          {/* pagination */}
          <div className="flex gap-4 justify-center">
            {totalPage.map((item, index) => {
              return (
                <button
                  onClick={() => {
                    setOffset(item);
                  }}
                  key={index}
                  className={`px-4 py-2 bg-button-green rounded-sm
                 text-gray-200 ${offset === item ? "opacity-100" : "opacity-50"}`}
                >
                  {item}
                </button>
              );
            })}
          </div>
          {/* end pagination */}
        </div>
        {/* end list top level */}
      </div>
    </div>
  );
};

export default HistoryRecharge;
