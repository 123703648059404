import React from "react";
import { Dialog } from "@headlessui/react";
const FormVerifyOTP = ({ onClose, otp, setOTP }) => {
  return (
    <div className="max-w-4xl p-6 mt-6 text-left bg-white shadow-xl rounded-2xl transition-all transform overflow-hidden">
      <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
        Verify OTP
      </Dialog.Title>
      <div className="mt-4 w-[30rem]">
        <input
          type="text"
          placeholder="OTP"
          className="placeholder-gray-400 w-full py-4 px-2 shadow-xl"
          name="otp"
          autoComplete="off"
          defaultValue={otp}
          onChange={(e) => setOTP(e.target.value)}
        />
      </div>

      <div className="mt-4 flex justify-center">
        <button
          type="button"
          className="inline-flex justify-center px-8 py-4 text-sm font-medium text-red-900 bg-red-100 border border-transparent rounded-md hover:bg-red-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-red-500"
          onClick={onClose}
        >
          Cancel
        </button>
        <button
          type="submit"
          className="inline-flex justify-center mx-2 px-8 py-4 text-sm font-medium text-green-900 bg-green-100 border border-transparent rounded-md hover:bg-green-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-green-500"
        >
          Verify
        </button>
      </div>
    </div>
  );
};

export default FormVerifyOTP;
