import React from "react";
import styled from "styled-components";

import * as IoIcons from "react-icons/io";

const Paymentwall = ({ url, onClose, detail }) => {
  return (
    <Container>
      <div className="flex w-3/4 h-2/3 mx-auto my-auto">
        <div className="bg-white h-1/2 w-1/5 px-4 py-4 space-y-2">
          <p className="font-semibold text-button-green">Bill detail</p>
          <hr></hr>
          <p>
            Account: <span className="font-medium">{detail.username}</span>
          </p>
          <hr></hr>
          <p>
            Package: <span className="font-medium">{detail.name}</span>
          </p>
          <hr></hr>
          <p>
            Price: <span className="font-medium">{"$" + detail.price}</span>
          </p>
          <hr></hr>
          <p>
            Quantity: <span className="font-medium">{detail.quantity}</span>
          </p>
          <hr></hr>
          <p className="text-red-500">
            Total: <span className="font-medium">{"$" + detail.price * detail.quantity}</span>
          </p>
        </div>
        {url !== "" && (
          <div className="flex flex-col w-full h-auto">
            <div className="w-3/4 py-2" style={{ background: "#1A1A1A" }} onClick={onClose}>
              <IoIcons.IoMdCloseCircle size="32" color="#ffffff" className="cursor-pointer ml-4" />
            </div>
            <iframe title="Dragon Heart Pingpack" src={url} className="w-3/4 h-full paymentwall" frameborder="0"></iframe>
          </div>
        )}
      </div>
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 99999;
  flex-direction: column;
  top: 0;
  left: 0;
  transition: 350ms;
`;
export default Paymentwall;
