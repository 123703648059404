const initialState = {
  isLogin: false,
  user: {},
  blocked: false,
  characters: [],
  point: "",
};
export default function drawerReducer(state = initialState, action) {
  // The reducer normally looks at the action type field to decide what happens
  switch (action.type) {
    case "USER-LOGIN":
      return {
        ...state,
        isLogin: action.payload,
      };
    case "USER-LOGOUT":
      sessionStorage.removeItem("_token");
      return {
        ...state,
        isLogin: action.payload,
      };
    case "USER-PROFILE":
      return {
        ...state,
        user: action.payload,
      };
    case "USER-BLOCKED":
      return {
        ...state,
        blocked: action.payload,
      };
    case "USER-CHARACTER":
      return {
        ...state,
        characters: action.payload,
      };
    case "GET-POINT":
      return {
        ...state,
        point: action.payload,
      };
    default:
      return state;
  }
}
