import crypto from "crypto";
import * as jwt from "jsonwebtoken";

const algorithm = process.env.REACT_APP_CRYPTO_ALGORITHM;
const password = Buffer.from(process.env.REACT_APP_CRYPTO_PASSWORD, "hex");
const ivLength = 16;

class CryptoEncrypt {
   AESEncrypt(text) {
      try {
         let iv = crypto.randomBytes(ivLength);
         let cipher = crypto.createCipheriv(algorithm, Buffer.from(password), iv);
         let encrypted = cipher.update(text);
         encrypted = Buffer.concat([encrypted, cipher.final()]);
         let encryptedHex = iv.toString("hex") + encrypted.toString("hex");
         return encryptedHex;
      } catch (err) {
         return "";
      }
   }
   AESDecrypt(text) {
      try {
         var iv = Buffer.from(text.substring(0, ivLength * 2), "hex");
         var encryptedText = Buffer.from(text.substring(ivLength * 2), "hex");
         var decipher = crypto.createDecipheriv(algorithm, Buffer.from(password), iv);
         var decrypted = decipher.update(encryptedText);
         return decrypted.toString();
      } catch (err) {
         return "";
      }
   }
   MD5Encrypt(text) {
      return crypto.createHash("md5").update(text).digest("hex");
   }

   RSADecrypt(text, key) {
      const privateKey = this.AESDecrypt(key);
      var buffer = new Buffer.from(text, "base64");
      const decrypted = crypto.privateDecrypt({ key: privateKey.toString("utf8") }, buffer).toString("utf8");
      return JSON.parse(decrypted);
   }
   JWTDecrypt(token) {
      return jwt.decode(token);
   }
}

export default new CryptoEncrypt();
