import { axiosInstance } from "./axios";
class ServerService {
    async getListServer() {
        return axiosInstance
            .get("/system/system-config-server")
            .then((response) => {
                return response.data;
            })
            .catch((error) => {
                return [];
            });
    }
}

export default new ServerService();
